.invoiceContainer {
  .topNavigation {
    text-align: left;
    margin: 10px 0 20px;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    a {
      text-decoration: none;
      color: #014671;
    }
    a:hover {
      cursor: pointer;
    }
  }

  .paperContainer {
    padding: 20px;
  }
  .download-button {
    padding: 8px 15px !important;
    margin-left: 5px !important;
  }
  .margin-btn {
    margin-right: 5px !important;
  }
  .invoice-button-align {
  display: flex;
}
}
.viewInvoiceContainer {
  P {
    margin: 0px 0px 20px;
  }
  .subTotalContainer {
    width: 50%;
    text-align: center;
    justify-content: center;
    text-align: center;
    padding: 10px 20px;
    background: #e5ecf0;
    border-radius: 10px;
    margin: 30px auto 15px;
    p {
      margin: 5px 0;
    }
  }
  .tableHeader {
    padding: 0 15px;
    background-color: #f4f4f4;
    p {
      margin: 15px 0;
    }
  }
  .evenContent {
    padding: 0 15px;
    background-color: #fcfcfc;
    p {
      margin: 15px 0;
    }
  }
  .oddContent {
    padding: 0 15px;
    background-color: #f8f8f8;
    p {
      margin: 15px 0;
    }
  }
}
/* con-167 */
.MuiGrid-container {
    margin-top: 0px;
}
.viewInvoiceContainer P {
    margin: 0 0 13px;
    font-size: 12px;
}
.viewInvoiceContainer .tableHeader p {
    margin: 8px 0;
}
.MuiOutlinedInput-inputMarginDense {
    padding-top: 6.5px;
    padding-bottom: 6.5px;
}
.viewInvoiceContainer .oddContent p {
    margin: 10px 0;
}
.viewInvoiceContainer .evenContent p {
    margin: 7px 0;
}
.viewInvoiceContainer .subTotalContainer {
    padding: 5px 20px;
    margin: 5px auto 0px;
}
.MuiDialogContent-root {
    padding: 0px 20px!important;
}
.MuiDialogActions-root.actionbtn {
    padding: 10px 24px;
}
.MuiDialogTitle-root {
    padding: 5px 24px!important;
}
.cancel-btn, .listbtn {
    padding: 8px 20px!important;
}

.viewInvoiceContainer .MuiGrid-root.subtaskrow-1.MuiGrid-container {
    grid-column-gap: 20px;
}
.viewInvoiceContainer .MuiOutlinedInput-input {
    padding: 18.5px 10px;
    font-size: 13px !important;
}
.MuiGrid-container {
  margin-top: 0px !important;
}