.greenshoots-label {
  width: 100%;
  // box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1) !important;
  padding: 10px;
  margin: 5px;
  .MuiFormControlLabel-label {
    color: black!important;
  }
  .MuiFormControl-root {
    width: 100% !important;
    max-width: 100% !important;
  }
}
.MuiFormControlLabel-root {
  margin-left: 0px !important;
}
.dropdown-input {
  .MuiFormControl-root {
    width: 100%;
    top: -14px;
  }
}

.file-upload {
  position: relative;
  top: 15px;
  .MuiButton-containedPrimary {
    background-color: #fff !important;
    color: grey!important;
    border: 2px dashed grey!important;
  }
  input {
    position: absolute;
    left: 0;
    top: -12px;
    opacity: 0;
  }
}

.chip {
  margin: 2px;
}

.chips {
  display: flex;
  flex-wrap: wrap;
}
.address-contaier{
  .addressError{
    font-size: 0.75rem;
    color: #f44336;
    float: left;
  }
  .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense{
    padding: 0 ;
  }
}
.phoneNumberError{
  font-size: 0.75rem;
  color: #f44336;
  float: left;
}

.phoneNumberContainer{
  width: 100%;
  // max-width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.phoneNumber{
  background: #F4F4F4;
}
.addProject .MuiGrid-root.dollarField {
  position: relative;
}
// .textField .MuiInputBase-marginDense {
//   padding: 0px!important;
// }