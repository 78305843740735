.asset-management{
    .topNavigation {
        .topNavigation {
            padding: 0px;
        }
    }
    .asset-heading{
        min-height: 40px;
        padding: 0px 0px 0px 20px;
        text-align: left;
        .asset-heading-text{
            font-size: 16px;
            font-weight: 400;
            color: #121212;
        }
    }
    .tableContainer{
        margin-top: 15px;
    }
    .headingContainer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .button{
        background: #014671;
        font-size: 11px;
        color: white;
        margin-left: 10px;
        box-shadow: none;
        border: 1px solid #014671;
    }
    .button:hover {
        background: transparent;
        color: #014671;
    }
    button.user-role-btn {
        background-color: #fff;
        color: #014671;
    }
    .assetCategoryContainer{
        .categoryName{
            text-align: left;
            color: gray;
            font-size: 13px;
        }
        .categoryCount{
            font-weight: 600;
            text-align: left;
            font-size: 20px;
            color: inherit;
            margin: 0;
        }
        .totalProjects{
            color: black;
        }
        .assetVehicle{
            color: green;
        }
        .assetPlant{
            color: green;
        }
        .categoryCard{
            .MuiCardContent-root{
                width: 100%;
                height: 99px;
            }
        }
        .selectedFilter{
            background: #fff !important;
            cursor: pointer;
        }
        .normalFilter{
            background: #f6f6f6 !important;
            cursor: pointer;
        }
    }
}

.createBtn {
    margin: 20px !important;
    background-color: #014671 !important;
    text-transform: unset !important;
}

.asset {
    .asset-heading {
        .asset-heading-text{
            font-size: 16px;
            font-weight: 400;
        }
    }
    .paper-container {
        margin-top: 15px;
        min-height: auto;
        height: auto;
        padding: 20px;
        .MuiGrid-spacing-xs-4 {
            margin: 0px;
        }
        .asset-information p {
            font-size: 14px;
            text-align: left;
            .asset-name {
                font-weight: bold;
            }
        }
        .businessInfo {
            display: unset;
            text-align: left;
        }
        .edit-form-element {
            display: flex;
            text-align: left;
            margin: 10px 0px;
        }
        .view-history-container {
            display: flex;
            justify-content: flex-end;
            .history-btn {
                margin: 5px 15px 20px;
            }
        }
        .asset-status-container {
            text-align: left;
            .asset-status-current {
                p {
                    font-size: 18px;
                    margin: 10px 0;
                }
            }
            .asset-status-info {
                font-size: 16px;
                p {
                    font-size: 14px;
                    a {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.view-history-dialog {
    text-align: left;
    margin: 10px 5px 15px;
    p {
        font-size: 16px;
        a {
            font-weight: bold;
        }
    }
}

.asset-maintenance {
    .asset-information p {
        font-size: 14px;
        text-align: left;
        .asset-name {
            font-weight: bold;
        }
    }
    .repair-attachment {
        p {
            font-weight: bold;
        }
        .attachmentText {
            display: flex;
            align-items: center;
            color: #014671;
            font-size: 16px;
            cursor: pointer;
            font-weight: 600;
            margin: 0px 0px 10px 0px;
            .MuiSvgIcon-root {
                flex-shrink:0;
                margin-right: 6px;
                fill: #014671;
            }
        }
        .attachmentText:hover {
            color: #333;
        }
    }
}

.buttonContainer{
    justify-content: flex-end;    
    border-top: 1px solid #e0e0e0;
    margin: 30px 0 0 0; 
    padding: 20px 0 0 0;
}

.attachedFiles {
    margin: 3px 0 0 7px;
    width: 100%;
    display: block;
    float: left;
    color: #333;
    a{
        color: #333;
        margin: 5px 0px 0px -7px;
    }
    p {
        color: #333; 
        float: left;
        max-width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        a{
            color: #333;
            margin: 0px!important;
        }
        a:hover {
          color: #014671;  
        }
        .MuiSvgIcon-root {
        margin-right: 6px;
        fill: #014671;
    }
    }
}

.filterTextAlign {
    text-align: left;
}
.asset .MuiButton-containedPrimary {
    background-color: #6DC9D1 ;
}
.asset  .MuiGrid-root.MuiGrid-container.subtaskrow-1.css-11lq3yg-MuiGrid-root {
    width: 33%;
}
.asset .addNewRoleCol {
    width: 320px;
}
.asset-management div.assetCategoryContainer .normalFilter {
    background: unset !important;
}
.categoryCard .categoryCount {
    font-size: 15px !important;
}