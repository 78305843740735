.content, .title {
    padding: 0 20px;
    margin-top: 10px;
  }
  p{
      font-size: 14px;
  }
  
  .custom-modal {
    overflow-y: scroll;
    z-index: 1201;
    position: fixed !important;
    top:6%;
    min-height: 450px;
    width: 65%;
    display: flex;
    box-shadow: inset 0 0 5px #ddd;
    background: #fff;
  }
  
  .MuiDialogTitle-root{
    background: white;
    color: black;
  }
  
  .primary{
    .MuiDialogTitle-root{
      background: white;
      color: black;
    }
}
.rfqdesc{
    .MuiFormControl-marginDense{
     width:100%;
    }
}
.createrfqcontainer{
  .MuiFormControlLabel-root{
    margin-right: 0px;
  }
}

.viewProject .icon .pan-tool {
  width: 26px;
  height: 17px;
}

