.viewProject .MuiGrid-root.MuiGrid-container.css-ryi1wy-MuiGrid-root.MuiGrid-root {
  justify-content: space-between;
}

// .viewProject .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-6:nth-child(2){
// width: 64px;
// flex-basis: 64px;
// }

.paperContainer .MuiGrid-container {
  justify-content: space-between;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: rgba(0, 0, 0, 0.6) !important;
}

.timesheetTable .table .MuiTableHead-root th {
  font-weight: 600 !important;
}

.custom-table-project .MuiTableCell-root.MuiTableCell-body {
  border-bottom: 1px solid #e7e7e7 !important;
}

.project-view-menuStyle {
  margin-left: 86%;
}

.custom-table-project .MuiTableBody-root.table-body tr td:nth-child(1) {
  color: #014671 !important;
  font-weight: 600 !important;
}

.categoryListColMobile .MuiGrid-grid-xs-2 {
  // max-width: 140px;
  // flex-basis: unset;
  // min-width: 140px;
  display: flex;
  align-items: center;
}

.progress-fin .project-detail {
  box-shadow: 1px 1px 1px 3px #f3f3f3;
  border-radius: 5px;
  padding: 20px 28px;
  margin: 0px 27px;
  min-height: 59px;
}

.categoryListColMobile .MuiGrid-grid-xs-1 {
  // max-width: 120px;
  // flex-basis: unset;
  // min-width: 120px;
  display: flex;
  align-items: center;
}

.categoryListColMobile .MuiGrid-grid-xs-3,
.categoryListColMobile .MuiGrid-grid-xs-4 {
  // max-width: 150px;
  // flex-basis: unset;
  // min-width: 150px;
  display: flex;
  align-items: center;
}

.categoryListColMobile>.MuiGrid-grid-xs-12 {
  overflow-y: hidden;
  overflow-x: auto;
}

.addStageContainer p {
  margin: 5px 0;
}

.categoryListColMobile .MuiGrid-item {
  padding: 0 5px !important;
}

.addStageContainer .taskTableHeader {
  display: flex;
  flex-wrap: unset;
}

.categoryListColMobile .cancel-btn,
.categoryListColMobile .listbtn {
  min-width: 100px !important;
}

.MuiGrid-item>span {
  font-size: 14px !important;
}

.categoryListColMobile {
  span.add-icon {
    display: none !important;
  }

  .MuiInput-root,
  .MuiFormControl-marginDense,
  .MuiGrid-item {
    height: 100%;
  }

  .MuiSelect-selectMenu {
    padding-top: 15px;
  }

  .row.no_result_section {
    width: 100%;
    display: flex;
  }

  .deleteIcon {
    margin-right: 0px;
    padding-top: 7px;

    button {
      padding: 5px 1px !important;
    }
  }

  .MuiOutlinedInput-input {
    padding: 18.5px 0px 18px 0;
  }

}

.MuiDialogContent-root {
  padding: 18px 20px !important;
}

.projectManagment {
  .projectCategoryContainer {
    .categoryName {
      text-align: left;
      color: gray;
      font-size: 13px;
    }

    .categoryCount {
      color: inherit;
      margin: 0;
    }

    .totalProjects {
      color: black;
    }

    .currentProjects {
      color: green;
    }

    .dlpProjects {
      color: #3a6cb3;
    }

    .lostProjects {
      color: purple;
    }

    .completedProjects {
      color: #2baa0e;
    }

    .cancelledProjects {
      color: #d0021b;
    }

    .archievedProjects {
      color: brown;
    }

    .categoryCard {
      .MuiCard-root {
        height: 100%;
      }

      .MuiCardContent-root {
        width: 100%;
      }
    }

    .selectedFilter {
      background: #fff !important;
      cursor: pointer;
    }

    .normalFilter {
      background: #f6f6f6 !important;
      cursor: pointer;
    }
  }

  .material-manufacturer {
    width: 80px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .tableContainer {
    margin-top: 15px;
  }

  .headingContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .button {
    background: #014671;
    font-size: 11px;
    color: white;
    margin-left: 10px;
    box-shadow: none;
    border: 1px solid #014671;
  }

  .button:hover {
    background: transparent;
    color: #014671;
  }

  .projectOverviewText {
    font-size: 16px;
    color: #333333;
    font-weight: 600;
  }
}

.addProject {
  .paperContainer {
    min-height: auto;
    height: auto;
    padding: 0 20px 20px 20px;
    width: 100%;
    float: left;

    .label {
      font-size: 14px;
      margin-top: 10px;
      text-align: left;
      color: #000;
      display: flex;
      align-items: center;
    }

    .MuiGrid-root {
      //   align-items: center;
    }

    .stateContainer {
      background-color: #eaeaea;

      .stageHeader {
        align-items: center;
        padding: 0 0px;

        p {
          font-size: 15px;
          color: #333333;
        }
      }

      .createStageLabel {
        float: right;
        margin-right: 12px;
        font-weight: 600;
        color: #3a6cb3;
        cursor: pointer;

        p {
          margin: 0;
        }
      }
    }

    .MuiFormControlLabel-root {
      margin-right: 0px;
    }

    .MuiStepConnector-horizontal {
      top: 14px !important;
      left: -175px;
      right: 100%;
    }

    .subtask-row {
      .MuiCheckbox-root {
        padding-top: 0px;
        padding-bottom: 0px;
      }

      p {
        margin: 10px 0;
      }
    }

    .createTask {
      background: #f4f4f4;
      padding: 2px 10px 13px;
      margin: 10px 0;

    }

    .projectName {
      font-size: 14px;
      color: #333333;
      text-align: left;
      border-bottom: 2px solid #f1f1f1;
      padding: 10px 0 20px 0;
    }

    .createStageLabel svg {
      margin: 0 10px -7px 0;
    }

    .optionsLabel {
      margin-right: 12px;
      float: left;
      font-weight: 600;
      color: #014671;
      margin-top: 10px !important;
    }

    .addNewRoleCol {
      display: flex;

      .addNewRole {
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #014671;
        font-weight: 600;
        font-size: 15px;
      }

      .addNewRole:hover {
        color: #000 !important;
      }
    }

    .MuiGrid-item {
      text-align: left;
      margin: 0px;
    }

    .modify {
      text-align: left;
      margin: 13px 0px;
      font-size: 14px;
      font-weight: 700;
      color: #014671 !important;
      text-decoration: none !important;
      cursor: pointer;
    }

    .modify:hover {
      color: #000 !important;
    }

    .MuiOutlinedInput-inputMarginDense {
      font-size: 14px;
      background: #f4f4f4;
      padding: 10.5px 14px !important;
    }

    .MuiFormControl-marginDense {
      width: 100%;
    }

    .button {
      display: block;
      background: #014671;
      font-size: 11px;
      color: white;
      box-shadow: none;
      border: 2px solid #014671;
    }

    .button:hover {
      background-color: #014671;
      color: #fff;
      border: 2px solid #014671;
    }

    p:hover {
      cursor: pointer;
    }

    .buttonContainer {
      display: flex;
    }

    .modify {
      text-align: left;
      margin: 13px 0px;
      font-size: 17px;
      font-weight: 700;
      color: blue;
      text-decoration: underline;
      cursor: pointer;
    }

    .descriptionInput {
      .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
        padding: 0px;
      }
    }

    .templateLable {
      border-right: 2px solid #ededed;
      text-align: left;
      margin: 10px 0;

      p {
        font-size: 14px;
        color: #333333;
        margin-top: 0px;
      }

      .changeLabel {
        margin-top: -7px;
        color: #014671;
        cursor: pointer;
        margin-bottom: 0px;

        .loaderbtn {
          color: #014671;

          .MuiSvgIcon-root {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .templateLabelContainer {
      /*border-bottom: 1px solid lightgray;*/
    }

    .addNewCustomer {
      font-size: 14px;
      color: #014671;
      font-weight: 400;
      margin: 10px 0 10px 0px;
      cursor: pointer;
      text-align: left;

      .AddNewCustomerIcon {
        color: #014671 !important;
        padding: 0px 10px;
        margin: -4px 0 0 0;

        .MuiSvgIcon-root {
          width: 18px;
          height: 18px;
        }
      }
    }

    .addNewCustomer:hover {
      color: #000;
      text-decoration: underline;
    }
  }

  .topNavigation {
    text-align: left;
    font-weight: 500;
    font-size: 16px;

    a {
      text-decoration: none;
      color: #014671;
    }

    a:hover {
      cursor: pointer;
    }
  }

  .stepperContainer {
    margin-top: 0px;

    .MuiStepper-root {
      padding: 0;
    }

    .MuiStep-horizontal {
      padding: 0px;
    }

    .stepperIicon {
      border-radius: 100%;
      width: 47px;
      height: 48px;
      background: #fff;
      z-index: 9;
      margin: 6px 0 0 0;

      .MuiStepIcon-text {
        fill: #ec684a;
      }
    }

    .MuiStepIcon-completed {
      width: 40px;
      height: 40px;
      color: #8bd17c;
    }

    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
      margin-top: 3px;
    }

    .Mui-disabled .MuiStepLabel-iconContainer {
      background: #ededed;
      border-radius: 100%;
      height: 40px;
      width: 40px;
      margin: -6px 0 0 0;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        fill: #666666;
        width: 20px;
        height: 20px;
      }
    }

    .MuiStepLabel-root {
      align-items: flex-start;
    }

    .completed {
      width: 40px;
      height: 40px;
    }
  }
}

.addStageContainer {
  .stageLabel {
    margin: 10px 0 !important;
  }

  p {
    font-size: 15px;
    font-weight: 600;
    margin: 20px 0;

    span {
      font-weight: 400;
      font-size: 14px;
      display: inline-block;
      width: 17%;
    }
  }

  .stageVisibility span {
    width: auto;

    .MuiIconButton-root {
      padding: 0px 5px 0px 0px;
    }

    .MuiIconButton-root:hover {
      background: none;
    }
  }

  .description {
    .MuiFormControl-marginDense {
      width: 100%;
    }
  }

  .MuiButton-containedSecondary {
    border: 2px solid #fff;
  }

  .editStage {
    width: 100%;
    text-align: right;
    border-top: 1px solid #dadada;
    padding-top: 15px;
  }

  .taskTableHeader {
    background: #f4f4f4;
    padding: 5px 0px;

    p {
      font-size: 14px;
      margin: 0px;
      padding: 6px 0px;
    }
  }

  .taskTable {
    p {
      font-size: 15px;
      margin: 0;
      border-right: 1px solid #f4f4f4;
      padding: 9px 15px;
      font-weight: 400;
    }
  }

  .taskList {
    height: 250px;
    overflow: auto;
  }

  .categoryRow {
    .MuiGrid-item {
      padding: 0 15px;
      display: flex;
      align-items: center;
    }

    .unitCol {
      .MuiInputBase-root {
        margin: 0px;
      }
    }
  }

  .note-cmd {
    display: flex;
    font-style: italic;
    font-size: 12px;
  }

  .addLaborbtn {
    color: #014671;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 0px;
    padding: 0px 15px;
  }

  .addLaborbtn:hover {
    color: #333;
  }

  .laborRow {
    .MuiGrid-item {
      padding: 0 15px;
      display: flex;
      align-items: center;
    }
  }

  span.removeBtn {
    font-size: 16px;
    color: red;
    cursor: pointer;
  }

  span.removeBtn:hover {
    color: #000;
    text-decoration: underline;
  }

  .total p {
    width: 54%;
    margin: 10px 0;
    display: flex;
    justify-content: space-around;

    span {
      display: inline-block;
      width: 15%;
      background: #f4f4f4;
      padding: 10px;
      margin-left: 14px;
    }
  }

  .total p span {
    display: inline-block;
    width: 20%;
    background: #f4f4f4;
    padding: 5px 10px;
    margin-left: 14px;
  }

  .taskTotal {
    p {
      width: 100%;
      padding: 10px 16px;
      margin: 0px;
      display: flex;
      justify-content: flex-end;
      font-weight: 400;
    }

    .costRight {
      p {
        background-color: #f4f4f4;
        padding-right: 15px;
      }
    }
  }
}

.button {
  display: block;
  background-color: #014671 !important;
  font-size: 11px !important;
  color: white !important;
  box-shadow: none !important;
  border: 2px solid #b2c7d4 !important;
}

.button:hover {
  background-color: #014671;
  color: #fff;
  border: 2px solid #014671;
}

.totalCost {
  margin-top: 28px;
  border-top: 1px solid #f1f1f1;
  padding-top: 30px;

  p {
    font-size: 15px;

    span {
      display: inline-block;
      width: 5%;
    }
  }

  .MuiInput-root {
    position: relative;
    padding: 7px 10px 6px;
  }

  .MuiInput-root:before {
    display: none;
  }
}

.createStageLabel {
  cursor: pointer;
  text-decoration: underline;
}

.MuiDialogTitle-root {
  padding-bottom: 0px !important;
}

.MuiDialogContent-root .content {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.stagesCost {
  width: 15%;
  display: inline-block;
  text-align: right;
}

.stageName {
  cursor: pointer;
  /*margin: 10px 0 0;*/
}

.plus-icon {
  margin: 10px 10px -7px 0;
  width: 23px !important;
  height: 23px !important;
  font-size: 42px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.createStageLabel.cancel-btn {
  margin-top: 20px !important;
  display: flex;
  width: 150px !important;
  border: 2px solid #6dc9d1 !important;
  background-color: #6dc9d1 !important;
}

.createStageLabel.cancel-btn:hover {
  border: 2px solid #6dc9d1 !important;
  background-color: #6dc9d1 !important
}

.projectableContainer .MuiTableCell-root.MuiTableCell-body.w-100 {
  padding: 2px 2px !important;
}

.MuiGrid-root.MuiGrid-container.createTask.css-11lq3yg-MuiGrid-root {
  margin: 10px 0px;
}

.variation-view-proposal {
  text-align: center;
  justify-content: end;

  .createStageLabel {
    display: inline-block;
    // margin: 10px auto !important;
    width: auto !important;
    padding: 10px 30px !important;
    text-decoration: none;

    .MuiSvgIcon-root {
      fill: #014671;
    }
  }
}

.createStage {
  text-align: center;

  .createStageLabel {
    display: inline-block;
    //margin: 10px auto !important;
    width: auto !important;
    padding: 10px 30px !important;
    text-decoration: none;

    .MuiSvgIcon-root {
      fill: #014671;
    }
  }

  .editprojectStage {
    display: inline-block;
    width: auto !important;
    padding: 10px 30px !important;
    text-decoration: none;
  }
}

.deleteIcon {
  margin-right: 20px;
  cursor: pointer !important;

  svg.MuiSvgIcon-root:hover {
    fill: #014671 !important;
  }

  .MuiSvgIcon-root {
    fill: #b2c7d4 !important;
    cursor: pointer !important;
  }
}

.vendorAutocomplete {
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    width: 100%;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 3px !important;
  }
}

.AddNewVendorIcon {
  color: #014671 !important;
  padding: 0px 10px;
  cursor: pointer;
  display: inline;

  .MuiButtonBase-root {
    color: #014671 !important;
  }

  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
}

.tableYScroll {
  max-height: 300px;
  height: 100%;
  overflow: auto;

  p span {
    color: #014671;
    font-weight: 700;
  }

  .MuiIconButton-root:hover {
    background-color: none;
  }

  .MuiSvgIcon-root {
    fill: #b2c7d4;
  }

  .budgetPrice {
    font-weight: 600;
    width: 20%;
    text-align: right;
  }
}

.proposalBtns span {
  width: 10%;

  .MuiButton-label {
    width: 100%;
  }
}

.selectItem {
  .MuiAutocomplete-hasClearIcon {
    width: 100% !important;
  }
}

.laborLevel {
  .MuiInput-formControl {
    margin-top: 0px !important;
  }

  p {
    margin: 0px;
  }
}

.addStageDesc textarea {
  padding: 12px;
}

.subtaskrow-1 {
  .MuiOutlinedInput-root {
    background-color: #f4f4f4;
    font-size: 14px;

    .MuiOutlinedInput-notchedOutline {
      border: 0px;
    }
  }

  .stageLabel {
    font-size: 15px;
  }

  .attachFile {
    input {
      width: 58%;
      margin-left: 5px;
      margin-bottom: 10px;
    }
  }

  .MuiFormControl-marginDense {
    margin: 0px;
    width: 100%;
  }

  label {
    font-size: 16px;
  }

  input {
    height: auto;
  }

  .MuiTypography-body1 {
    font-size: 15px;
  }
}

.viewRfqContainer {
  .rfqNameLabel {
    display: flex;
    padding: 0 0px;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 10px 0px;
    }
  }

  .submitQuote {
    color: #014671;
    cursor: pointer;
  }

  .reviewQuote {
    color: #f49545;
    cursor: pointer;
  }

  .reviewQuote:hover,
  .submitQuote:hover,
  .viewPO:hover {
    text-decoration: underline;
    color: #333333;
  }

  .viewPO {
    color: #014671;
    cursor: pointer;
    text-decoration: underline;
  }
}

.viewRFQScreen {
  .paperContainer {
    padding: 20px !important;
  }

  .view-rfq-navigator {
    display: flex;
    justify-content: space-between;
  }
}

.openDraft {
  color: #014671;
  font-weight: 600;
  cursor: pointer;
  font-size: 12px;
}

.openDraft:hover {
  color: #333333;
}

.submitQuoteContainer {
  .descriptionContainer {
    text-align: left;

    .stageLabel {
      font-size: 15px;
    }

    .attachFile {
      input {
        width: 58%;
        margin-left: 5px;
      }
    }
  }

  .totalLabel {
    float: right;
    margin-right: 20;
    margin-right: 47px;
    font-size: 18px;
  }

  .switchContainer {
    text-align: left;

    .MuiSwitch-thumb {
      background-color: #014671;
    }

    .MuiSwitch-track {
      background-color: #b2c7d4;
    }

    .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
      background-color: #014671;
    }
  }
}

.RFQsent {
  font-size: 14px;
  color: #333333;
  margin: 10px;
}

.viewProject {
  width: 100%;
  float: left;

  svg.MuiSvgIcon-root {
    width: 24px;
    height: 30px;
  }

  .mobileOverviewCard {
    width: 100%;
    background: #fff;

    .MuiPaper-elevation1 {
      box-shadow: none !important;
    }

    .mobileViewProject {
      padding: 20px;
      border-radius: 0px 4px 4px 4px;
      box-shadow: none !important;
      background: #fff;
    }

    .overviewCard {
      display: none;
    }
  }

  .overviewContainer {
    display: flex;
    flex-direction: row;
  }

  .projectName {
    font-size: 20px;
    font-weight: 600;
    color: #014671;
    text-align: left;
    display: flex;
    align-items: center;
    padding: 0px !important;
    border: none !important;
  }

  .headerInfo {
    display: flex;
  }

  .stockHeader {
    display: flex;
    justify-content: flex-end;

    p {
      margin-right: 14px;
      margin-top: 0;
      color: blue;
      cursor: pointer;
    }

    .cancel-btn-white {
      margin-right: 10px !important;
    }
  }

  .projectInfo {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin: 0px 5px;
      text-decoration: none;
    }
  }

  .headerColumn {
    width: 12%;
    font-weight: 500;
    text-align: center;
  }

  .percentage-complete-edit {
    // display: flex;
    // justify-content: center;
  }

  .stageName {
    color: #014671;
    font-weight: 600;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 116px;
    white-space: nowrap;
  }

  .createTask {
    background: #f4f4f4;
    padding: 2px 10px 13px;
    margin: 10px 0;
  }

  .optionsLabel {
    margin-right: 12px;
    float: left;
    font-weight: 600;
    color: #014671;
    margin-top: 10px !important;
  }
}

.receiveStockContainer {
  .totalCount {
    display: flex;
    justify-content: flex-end;
  }

  .countLabel {
    margin: 13px 20px 0px;
    font-size: 17px;
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    background: #f6f6f6;
    text-align: left;
  }

  .de-stock-margin {
    margin: 20px 5px;
    text-align: left;
    padding: 0 12px;
  }
}

.timeSheetContainer {
  padding: 0px;
  margin: 10px;

  .addResources {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
    text-align: right !important;
    font-size: 20px;
    margin: 10px 0px 0px;
  }

  .MuiGrid-root {
    text-align: left;
  }

  .task {
    margin-top: 20px;
    font-size: 20px;

    .info-icon {
      margin-top: -10px;
    }
  }
}

.attachmentText {
  display: flex;
  align-items: center;
  color: #014671;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
  margin: 0px 0px 10px 0px;

  .MuiSvgIcon-root {
    flex-shrink: 0;
    margin-right: 6px;
    fill: #014671;
  }
}

.attachmentText:hover {
  color: #333;
}

.attachedFiles {
  margin: 3px 0 0 7px;
  width: 100%;
  display: block;
  float: left;
  color: #333;

  a {
    color: #333;
    margin: 5px 0px 0px -7px;
  }

  p {
    color: #333;
    float: left;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px !important;

    a {
      color: #333;
      margin: 0px !important;
    }

    a:hover {
      color: #014671;
    }

    .MuiSvgIcon-root {
      margin-right: 6px;
      fill: #b2c7d4 !important;
    }

    .MuiSvgIcon-root:hover {
      fill: #014671 !important;
    }
  }
}

.attachedFilesAssign {
  margin: 3px 0 0 7px;
  width: 150%;
  display: block;
  float: left;
  color: #333;

  a {
    color: #333;
    margin: 5px 0px 0px -7px;
  }

  p {
    color: #333;
    float: left;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;

    a {
      color: #333;
      margin: 0px !important;
    }

    a:hover {
      color: #014671;
    }

    .MuiSvgIcon-root {
      margin-right: 6px;
      fill: #014671;
    }
  }
}

textarea {
  padding: 0px !important;
}

.assign-task-container {
  width: 100%;
  float: left;

  .assign-task-input {
    margin-left: 10px;
    margin-top: -5px !important;
    width: 97%;
  }

  .MuiOutlinedInput-inputMarginDense {
    background-color: #f4f4f4;
    font-size: 14px;
  }

  .assign-task-select {
    margin-left: 10px;
    margin-top: -10px !important;
  }

  .stage {
    text-align: left;

    .stage-name-title {}

    .stage-name {
      font-size: 24px;
      color: #333333;
    }
  }

  .owner {
    text-align: left;
    margin: 10px 0px 20px 0px !important;

    .owner-name {
      color: #333333;
    }

    .attachments {
      margin-left: 20px;
      color: #333333;
    }
  }

  .task-progress {
    .task-select-input {
      display: flex;
      color: #707070;
    }
  }

  .MuiGrid-container {
    text-align: left;
    margin: 10px 0px;
  }

  .assign-task-form {
    text-align: left;
    font-size: 14px;
    color: #333;

    .form-grid {
      display: flex;
    }

    .start-date-container {
      .start-date {
        display: flex;
      }

      .duration {
        display: flex;
      }
    }
  }

  .comments-divider {
    width: 100%;
    height: 1px;
    margin: 20px 0px;
  }

  .comments {
    text-align: left;

    .comments-title {
      color: #333333;
      font-size: 16px;
    }

    .comments-text {
      width: 50%;
    }

    .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
      padding: 0px;

      textarea {
        padding: 10.5px 14px !important;
      }
    }
  }
}

.back-button-execution {
  float: left;
}

.viewProjectCol {
  box-shadow: none !important;
  padding: 0px !important;
}

.assignContainer {
  width: 100%;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 1px 7px -1px rgba(0, 0, 0, 0.2);
  float: left;
}

.projectOverviewContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  background: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 10px !important;

  .categoryCard {
    margin-bottom: 10px;
    width: unset;
    background: #8bd17c;
    padding: 0px !important;

    .MuiCard-root {
      height: 100%;
    }

    .categoryName {
      color: #333;
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 0px;
    }

    .categoryCount {
      span {
        display: inline-block;
        margin: 10px 0px 0px;
      }

      button {
        float: right;
        padding: 0px;
        background: #014671;
        padding: 7px 7px 2px;

        .MuiIconButton-label {
          margin-top: 0px;

          .MuiSvgIcon-root {
            fill: #fff;
          }
        }
      }
    }

    .MuiIconButton-root:hover {
      box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.4) !important;
    }

    .categoryTotalHeading {
      color: #333;
      font-size: 14px;
      margin-bottom: 0px;
      text-align: left;
    }

    .budgetPink {
      background-color: #f6e3d4;
    }

    .rfqBlue {
      background: #d1dae9;
    }



    .MuiCardContent-root {
      padding: 10px 12px !important;

      .categoryName,
      .categoryTotalHeading {
        margin-top: 2px;
      }

      .MuiIconButton-root {
        font-size: 1rem;
      }
    }
  }

  .mainGrid {
    display: block;

    .marginType {
      .MuiFormControl-marginDense {
        width: 75%;
      }
    }
  }
}

.viewCheckbox {
  label {
    width: 100%;
  }

  span {
    font-size: 14px;
    width: auto !important;
  }
}

.overviewCard {
  .projectInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    text-align: left;
    height: 130px;
    width: 140px;
    flex-direction: column;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
    padding: 10px 15px 10px 22px;
    border-radius: 4px 4px 0px 0px;

    p {
      font-size: 14px;

      span {
        display: inline-block;
        width: 60%;
        font-size: 12px;
      }
    }
  }

  p {
    float: left;
    width: 100%;
    margin: 10px 0 0 !important;
  }
}

.timesheetTable {
  text-align: center;
}

.templates-container {
  width: 100%;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;

  .template-loader {
    justify-content: center;
    margin-left: 48%;
  }

  .template-selected {
    color: #fff;
    background-color: #014671;

    h4 {
      color: #fff;
    }

    h6 {
      color: #fff;
    }
  }

  .template-box {
    width: 94%;
    height: 220px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    padding: 5px 15px 10px;
    position: relative;

    h4 {
      font-size: 16px;
      margin: 10px 0;
    }

    h6 {
      font-size: 14px;
      margin: 0px;
      font-weight: 400;
    }

    .template-delete-button {
      position: absolute;
      color: red;
      top: 2px;
      right: 2px;
    }
  }

  .MuiFormControlLabel-root {
    margin: 0px 0px 20px 0px;
  }
}

.variationContainer {
  .topNavigation {
    text-align: left;
    font-weight: 500;
    font-size: 16px;

    a {
      text-decoration: none;
      color: #014671;
    }

    a:hover {
      cursor: pointer;
    }
  }

  .filter {
    .MuiFormControl-fullWidth {
      margin: 0px;
    }

    .add {
      width: 10%;
      margin: 10px;
    }
  }

  p {
    float: left;
  }
}

.tenderTemplate {
  .MuiFormControl-marginDense {
    width: 100%;
  }
}

.MuiDialogActions-root.actionbtn {
  padding: 24px;
}

.MuiDialogContent-root {
  .addProject {
    .paperContainer {
      padding: 10px 0px;
      box-shadow: none !important;
    }
  }
}

.reasonField {
  .textField {
    width: 100%;
  }
}

.budget-container {
  .budget-div {
    width: 100%;
    padding: 1px 20px 20px !important;
    border-radius: 0px 4px 4px 4px;
    box-shadow: none !important;
    background: #fff;
  }
}

.viewCheckbox {
  margin: 10px 0 !important;

  .MuiCheckbox-colorPrimary.Mui-checked {
    padding-left: 0px;
    color: #000000;
  }

  .MuiCheckbox-colorPrimary.Mui-checked:hover {
    background: none;
  }
}

.viewTaskScreen span {
  width: auto !important;
  font-weight: 400 !important;
}

.viewTaskScreen p {
  margin: 15px 0 !important;
}

.topNavigation {
  text-align: left;
  font-weight: 500;
  font-size: 16px;

  a {
    text-decoration: none;
    color: #014671;
  }

  a:hover {
    cursor: pointer;
  }
}

.stepCostTotal {
  margin: 0 0 10px 0;

  p {
    margin: 12px 20px;
    text-align: right;
  }

  .MuiInputAdornment-positionStart p {
    margin: 0px;
  }
}

.scndStep.paperContainer {
  .stepperContainer {
    margin: 0px;
  }

  .MuiStepLabel-iconContainer {
    width: 40px;
    height: 40px;
    background: #faece9;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ef8c75;

    svg {
      width: 20px;
    }
  }

  .MuiStep-completed {
    .MuiStepLabel-iconContainer {
      background: transparent;
      border: none;

      svg {
        width: 48px;
        height: 52px;
      }
    }
  }

  .MuiStepConnector-horizontal {
    top: 20px !important;
  }
}

.createrfqcontainer {
  margin: 0px !important;
}

.MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track,
.submitQuoteContainer .switchContainer .MuiSwitch-track {
  // background-color: #ec684a!important;
  opacity: 1 !important;
}

.switchContainer {
  text-align: left;
}

.submitQuoteContainer .switchContainer .MuiSwitch-thumb {
  background: #fff !important;
}

.addAssetModal {
  .MuiPaper-elevation2 {
    box-shadow: none;
  }

  .MuiTableCell-root {
    border: 0px;
  }

  .table td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft {
    height: 52px !important;
  }

  .cancel-btn-white {
    margin: 0px !important;

    .projectPOContainer {
      .headingContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 10px;
      }
    }
  }
}

.venderPaymentSelect {
  width: 100%;

  .MuiFormControl-fullWidth {
    flex-direction: inherit;
    margin: 0px;

    .MuiOutlinedInput-root {
      background-color: #fff;
    }
  }
}

.task-tabs {
  .MuiPaper-elevation4 {
    box-shadow: none;

    .MuiTab-wrapper {
      color: #666666;
      align-items: flex-start;
    }

    .MuiTab-root {
      padding: 13px 12px;
    }

    .MuiTab-textColorPrimary {
      background-color: #fcfcfc;
      margin-right: 10px;
      text-transform: unset;
      border-radius: 4px 4px 0 0;
      padding: 0 24px;
    }
  }

  .MuiTypography-root {
    margin: 0px;
  }

  .tabPanel,
  .MuiTab-textColorPrimary.Mui-selected {
    background: #f8f8f8;

    .tabsHeading {
      p {
        font-size: 14px;
        font-weight: 400;
        margin: 10px 0 0 0;
      }
    }

    .MuiFormControl-marginDense {
      width: 100%;

      .MuiOutlinedInput-root {
        border: 0px;
        background-color: #eaeaea;
        // .MuiOutlinedInput-notchedOutline {
        //   border: none;
        // }
      }
    }
  }

  .PrivateTabIndicator-colorSecondary-54 {
    display: none;
  }

  .MuiTabs-indicator {
    display: none;
  }
}

.add-material-po {
  .table-container {
    .small-cell {
      min-width: 100px;
      max-width: 150px;
    }

    .table-head {
      background-color: rgba(220, 220, 220, 0.5);
    }

    .even-row {
      background-color: rgba(220, 220, 220, 0.5);
    }

    .MuiTableCell-root {
      border: 0px;
      text-align: center;
    }

    .MuiAutocomplete-root {
      width: 100%;
    }
  }
}

.rfqContainer,
.variationContainerCol {
  background: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: auto;

  p {
    text-align: left;
    word-break: break-all;
  }
}

.variationContainerCol {
  padding: 20px;
}

.itemsField {
  .MuiInput-marginDense {
    margin-top: 0px;
  }

  label {
    margin-top: -13px;
  }
}

.invoice-url {
  color: #014671;
}

.filterSection {
  justify-content: flex-end;
  margin-top: -12px;
  width: 50%;
  display: flex;
}

.container {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.create-rfq-items {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.viewProjectCol {
  .table-head {
    .MuiTableCell-root.MuiTableCell-head:nth-child(1) {
      width: 10%;
    }

    .MuiTableCell-root.MuiTableCell-head:nth-child(2) {
      width: 20%;
    }
  }

  .totalCost {
    .MuiGrid-grid-xs-2 {
      p {
        text-align: right;
        padding-right: 15px;
      }
    }
  }
}

.budget-retention {
  .MuiPaper-elevation2 {
    box-shadow: none;
  }
}

.variationFilter {
  .MuiOutlinedInput-marginDense {
    background-color: #fff;
    text-align: left;
  }
}

.downloadVariation {
  button {
    float: none !important;
  }
}

.mobileQuoteTable {
  margin: 20px 0;
}

.addProject.addItems {
  .paperContainer {
    padding: 0px !important;
  }
}

.projectPOContainer {
  .topNavigation {
    width: 50%;
    float: left;
  }
}

.privewTable {
  width: 100%;
  overflow: auto;

  .table-body {
    .MuiTableCell-root {
      padding: 6px 15px;

      p {
        margin: 0px;
      }
    }
  }

  tbody.table-body tr {
    display: flex;
    width: 100% !important;
    text-align: left;
    padding: 0px !important;
    margin: 0px;
  }

  tbody.table-body div div {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-content: baseline;
  }

  tbody.table-body tr td {
    width: 100%;
    text-align: left;
    padding: 10px 3px !important;
  }

  .sub-task .left-img {
    left: 15px;
    position: relative;
  }

  thead.table-head tr th {
    width: 100% !important;
  }

  thead.table-head tr {
    display: flex;
    width: 100% !important;
    text-align: left;
    padding: 0px !important;
    margin: 0px;
  }

  thead {
    background: transparent;
  }
}

.projectPOContainer {
  .addCustomer {
    width: 100%;
    float: left;

    .paperContainer {
      overflow: auto;
    }
  }
}

.table-body tr td.newStock {
  text-align: left !important;
}

.receiveStockContainerMobileCol {
  margin: 10px 0;

  p {
    margin: 0px;
  }
}

.viewInvoiceScroll {
  p {
    margin: 0px !important;
  }
}

.invoiceContainer {
  .topNavigation {
    .topNavigation {
      padding: 0px;
    }
  }
}

.receiveDeStockContainer {
  p {
    margin: 0px;
  }
}

.topNavigation {
  width: 55%;
  min-height: 40px;
}

.paperContainer.newSupplier {
  padding: 0px !important;
  box-shadow: none !important;
}

.proposalDialogScroll {
  margin-bottom: 10px !important;
}

@media (max-width: 1366px) {
  .budget-container {
    .budget-div {
      .data-table {
        overflow-x: auto;

        .table-container {
          width: 1048px;
        }
      }
    }
  }

  .vendorPaymentContainer,
  .viewInvoiceContainer,
  .receiveDeStockContainer {
    overflow: auto;

    .vendorPaymentMobile,
    .viewInvoiceMobile,
    .receiveDeStockContainerMobile {
      width: 200px;

      .vendorPaymentMobileScroll,
      .viewInvoiceScroll,
      .receiveDeStockContainerScroll {
        width: 1315px;
        margin-bottom: 10px;
      }
    }
  }

  .addProject .paperContainer .MuiStepConnector-horizontal {
    left: -143px !important;
  }
}

@media (max-width: 1280px) {
  .projectOverviewContainer {}

  .budget-container {
    .budget-div {
      .data-table {
        .table-container {
          width: 960px;
        }
      }
    }
  }

  .add-material-po,
  .receiveStockContainerMobile {
    overflow-x: auto;

    .table-container,
    .receiveStockContainerMobileCol {
      width: 200px;

      table,
      .receiveStockContainerMobileScroll {
        width: 1155px;
      }
    }
  }
}

@media (max-width: 1170px) {
  .viewProject {
    overflow-x: scroll;

    .main {
      width: 1580px;
    }

    .overviewCard {
      display: none;
    }

    .overviewContainer {
      flex-direction: column-reverse;
      width: 890px;

      .projectOverviewContainer {
        width: 100%;
        margin-left: 0px;

        .mainGrid {
          display: flex;
          flex-wrap: unset;

          .categoryCard {
            margin-right: 20px;
          }
        }
      }

      .mobileOverviewCard {
        background: transparent;

        .overviewCard {
          display: block;

          .projectInfo {
            box-shadow: none;
          }
        }

        .mobileViewProject {
          padding: 0px;

          .main {
            padding: 20px;
          }
        }
      }
    }
  }

  .budget-container {
    .budget-div {
      .data-table {
        .table-container {
          width: 852px;
        }
      }
    }
  }

  .projectOverviewContainer .categoryCard .MuiCardContent-root {
    padding: 0px 12px 20px !important;
  }

  .createTotalBudge {
    width: 55% !important;
  }

  .totalCost {
    .MuiFormControl-root .MuiTextField-root {
      width: 100%;
    }

    .MuiFormControl-root.MuiTextField-root {
      width: 100%;
    }
  }

  .rfqList {
    overflow-x: auto;

    .MuiTableContainer-root {
      width: 300px;

      table {
        width: 1024px;
      }
    }
  }

  .mobileQuoteTable {
    overflow-x: auto;
    margin-bottom: 20px;

    .mobileQuoteTableCol {
      width: 200px;

      .mobileQuoteTableresponsive {
        width: 1200px;
      }
    }
  }

  .variationFilter {
    margin-top: -9px;
    width: 64% !important;
  }

  .receiveInvoicePO {
    overflow-x: auto;

    .receiveInvoicePOScroll {
      .MuiTableContainer-root {
        width: 1486px;

        p {
          margin: 0px !important;
        }
      }
    }
  }

  .addProject .paperContainer .MuiStepConnector-horizontal {
    left: -175px !important;
  }

  .tableYScroll {
    .MuiTableContainer-root {
      width: 200px;

      .MuiTable-root {
        width: 1280px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .budget-container {
    .budget-div {
      .data-table {
        .table-container {
          width: 935px;
        }
      }
    }
  }

  .topNavigation,
  .headingContainer {
    padding: 0px;
  }

  .createTotalBudge {
    width: 55% !important;
  }

  .variationFilter {
    width: 67% !important;
  }

  .categoryList {
    overflow-x: auto;
    margin-bottom: 20px;

    .categoryListCol {
      width: 200px;

      .categoryListColMobile {
        width: 1150px;

        .MuiGrid-grid-xs-12 {
          margin-bottom: 10px !important;
        }
      }
    }
  }

  .addProject .paperContainer .MuiStepConnector-horizontal {
    left: -200px !important;
  }
}

@media (max-width: 768px) {
  .viewProjectCol .table-head .MuiTableCell-root.MuiTableCell-head:nth-child(2) {
    width: auto !important;
  }

  .projectPOContainer {
    .topNavigation {
      width: 100%;
      margin: 0px 0px 10px 0px !important;
    }

    .poNavigator {
      float: left !important;
      margin: 0px 0px 0px 0px !important;

      .cancel-btn-white {
        margin-left: 0px !important;
      }
    }
  }

  .projectOverviewMobile {
    overflow-x: auto;
    width: 100%;

    .projectOverviewScroll {
      width: 720px;

      .projectCategoryContainer,
      .tableContainer {
        width: 1000px;
      }
    }
  }

  .poverlistMobile {
    margin-top: 20px;
    float: left;
  }

  .viewProject {
    .overviewContainer {
      width: 500px;
    }
  }

  .budget-container {
    .budget-div {
      .data-table {
        .table-container {
          width: 600px;
        }

        .generateContainer {
          .MuiTable-root {
            width: 900px;
          }
        }

        .budgetTableMobile {
          table {
            width: 1107px;
          }
        }
      }

      .total-cost.geenrateTotal {
        width: 100%;
        margin-top: 20px;
      }
    }
  }

  .createTotalBudge {
    width: 100% !important;
  }

  .budget-retention {
    .table div div div table {
      width: 895px;
    }
  }

  .createRFQAddBtn {
    text-align: center;

    button {
      float: none !important;
      margin: 0px auto !important;
    }
  }

  .mobileCreateRfqCol,
  .variationlist,
  .viewPoMobileContainer {
    overflow-x: auto;

    .mobileCreateRfq {
      width: 768px;
      max-width: 768px;
      padding-bottom: 20px;
    }

    .mobileViewRFQ,
    .variationlistMobile {
      width: 200px;

      .variationlistMobileCol {
        width: 1024px;
      }
    }

    .viewPoMobileContainerCol {
      margin: 10px 0;
      width: 200px;

      .viewPoMobileContainerScroll {
        width: 850px;
      }
    }
  }

  .viewRFQpara {
    p {
      margin: 0px 0px 10px 0px;
    }
  }

  .variationFilter {
    width: 60% !important;

    .variationFilterlist {
      width: 42% !important;
    }
  }

  .addLabourCol,
  .taskListCol {
    overflow-x: auto;
    margin-bottom: 30px;

    .addLabourMobile,
    .taskListMobile {
      width: 200px;

      .projectAddLabourMobile,
      .taskListMobileCol {
        width: 900px;
      }
    }
  }

  .addTaskTabs {
    .taskTotal {
      margin-top: 20px;
    }
  }

  .addProject.addItems {
    .addNewRoleCol {
      .addNewRole {
        width: 100%;
      }
    }
  }

  .add-material-po {
    .table-container {
      table {
        width: 1200px;
      }
    }
  }

  .InvoiceMobileContainer {
    overflow: auto;

    .InvoiceMobileContainerCol {
      width: 200px;

      .InvoiceMobileContainerScroll {
        width: 1000px;
      }
    }
  }

  .addProjectAttachment {
    margin-bottom: 20px;
  }

  .addProject .paperContainer .MuiStepConnector-horizontal {
    left: -300px !important;
  }

  .addStageContainer p span {
    width: 31%;
  }
}

@media (max-width: 650px) {

  .headingContainer,
  .budget-navigator {
    flex-direction: column !important;
    justify-content: flex-start !important;
    height: auto;

    .btnPrimary {
      margin-top: 0px !important;
    }
  }

  .userOverviewText,
  .topNavigation {
    text-align: left;
    padding-bottom: 20px;
  }

  span {
    margin: 0px !important;
    text-align: left;

    .btnPrimary {
      margin: 0px 0px 20px 0px !important;
      float: none !important;
    }
  }

  .addProject .stepperContainer .Mui-disabled .MuiStepLabel-iconContainer {
    margin: -6px 0 0 0 !important;
  }

  .projectOverviewMobile {
    .projectOverviewScroll {
      width: 450px;

      .inventoryStockContainer {
        width: 900px;
      }
    }
  }

  .poverlistMobile {
    margin-top: 0px;
  }

  .Homelayout.MuiPaper-elevation4 {
    .mainTopbar {
      .avatarLogoContainer {
        display: none;
      }

      .avatarBorderLeft {
        display: none;
      }
    }
  }

  .viewProject {
    .main {
      width: 1585px;
    }

    .overviewContainer {
      width: 300px;
    }
  }

  .mobileUser {
    display: block !important;
    text-align: center;

    button {
      margin: 0px;
      padding: 0px;

      .MuiAvatar-root {
        width: 48px;
        height: 48px;
        order: 1px solid #e69494;
      }
    }

    p {
      margin: 0px 0px 5px 0px;
    }

    .designation {
      font-size: 12px;
    }

    .MuiAvatar-colorDefault {
      color: #fff;
      background-color: #014671 !important;
    }
  }

  .budget-container {
    .budget-div {
      .data-table {
        .table-container {
          width: 200px;
        }

        .budgetTableMobile {
          table {
            width: 1107px;
          }
        }

        .generateContainer {
          .MuiTable-root {
            width: 790px;
          }
        }
      }
    }
  }

  .createTotalBudge {
    width: 100% !important;
    padding: 12px 20px 12px 20px !important;
  }

  .budgetTotal {
    margin: 12px 0;
  }

  .budget-retention {
    button {
      margin: 2px !important;
    }
  }

  .viewRFQScreen {
    .view-rfq-navigator {
      flex-direction: column;
      display: flex;
      justify-content: flex-start;

      button {
        margin: 0px 0px 20px 0px !important;
        float: left !important;
      }
    }
  }

  .viewRfqContainer {
    .rfqNameLabel {
      flex-direction: column;
      align-items: flex-start;
    }

    .rfqList {
      margin-bottom: 20px;
    }
  }

  .variationFilter {
    width: 100% !important;
    justify-content: flex-start;

    .variationFilterlist {
      width: 36% !important;
      margin-right: 10px;
    }
  }

  .tableXScroll {
    width: 200px;

    .tableXScrollMobile {
      width: 600px;

      button {
        margin-bottom: 0px !important;
      }
    }
  }

  .viewlaborCol {
    overflow-x: auto;

    .viewlaborMobile {
      width: 200px;

      .viewlaborMobileCol {
        width: 768px;
      }
    }
  }

  .projectPOContainer {
    .topNavigation {
      width: 100%;
    }

    .poNavigator {
      float: left !important;
      margin: 0px !important;

      .cancel-btn-white {
        margin: 0px 0px 20px 0px !important;
      }
    }
  }

  .addProject .paperContainer .MuiStepConnector-horizontal {
    left: -200px !important;
  }

  .addStageContainer p {
    word-break: break-word;

    span {
      width: 45%;
    }
  }
}

@media (max-width: 576px) {
  .projectPOContainer {
    .topNavigation {
      margin-bottom: 0px !important;
    }

    .addCustomer {
      .businessInfo {
        p {
          margin-bottom: 0px;
        }
      }
    }

    .cwoMobilecontainer {
      overflow-x: auto;

      .cwoMobiletable {
        width: 200px;

        .cwoMobiletableCol {
          width: 600px;

          .MuiGrid-container {
            margin-top: 0px !important;
          }
        }
      }
    }
  }

  .viewInvoiceContainer {
    .subTotalContainer {
      width: 100% !important;
    }
  }

  .paperContainer.newSupplier {
    .MuiGrid-grid-md-6 {
      .MuiGrid-grid-sm-7 {
        margin-bottom: 10px;
      }
    }
  }

  .addProject {
    .MuiGrid-grid-md-9 {
      .MuiGrid-container {
        margin-bottom: 10px;
      }
    }
  }

  .addStageContainer p span {
    width: 100%;
  }

  .viewDescription {
    p {
      margin-bottom: 0px !important;
    }

    .MuiGrid-grid-md-7 {
      p {
        margin-top: 5px !important;
        margin-bottom: 10px !important;
      }
    }
  }

  .viewTaskListMobile {
    margin: 32px 20px 32px 0px !important;
  }
}

@media (max-width: 479px) {
  .projectOverviewMobile {
    .projectOverviewScroll {
      width: 200px;
    }
  }

  .budget-container .budget-div {
    .data-table .budgetTableMobile table {
      width: 750px;
    }

    .totalCost {
      .MuiGrid-grid-sm-2 {
        padding: 12px 15px 12px 10px !important;
        text-align: right;
      }

      p {
        width: 100% !important;
        padding: 12px 10px 12px 10px !important;
      }
    }
  }

  .vendorAutocomplete {
    text-align: left;

    .addNewRole {
      text-align: left;
    }

    .AddNewVendorIcon {
      line-height: 44px;
    }
  }

  .mobileCreateRfqStage {
    margin: 0 0 20px 0 !important;
  }

  .RfqActionButtons.MuiDialogActions-spacing {
    flex-direction: column-reverse;

    button {
      width: 100% !important;
      margin: 0 0 10px 0 !important;
    }
  }

  .previewRFQCol {
    margin: 0px !important;
  }

  .variationFilter {
    .variationFilterlist {
      width: 50% !important;
    }
  }

  .tableXScrollMobile {
    .createStageLabel.cancel-btn {
      margin: 0px 0px 10px 0px !important;
    }
  }

  .addNewRoleCol {
    padding-left: 0px !important;
  }

  .mobileCreateRfqCol {
    .mobileCreateRfq {
      max-width: 600px;
      width: 600px;

      p {
        word-break: break-all;
      }
    }
  }

  .descriptionContainer {
    p {
      margin-bottom: 5px;
    }
  }

  .receiveStockContainer {
    .de-stock-margin {
      margin: 0px 0px 20px 0px !important;
    }
  }

  .topNavigation {
    width: 100%;

    .outerSpan {
      display: block !important;

      .projectName {
        display: inline;
      }
    }
  }

  .addProject .paperContainer .MuiStepConnector-horizontal {
    left: -136px !important;
  }
}

@media (max-width: 450px) {
  .viewInvoiceContainerMobile {
    overflow-x: auto;

    .viewInvoiceContainerMobileCol {
      width: 200px;

      .viewInvoiceContainerMobileScroll {
        width: 350px;
      }
    }
  }

  .stepCostTotal {
    .MuiGrid-grid-xs-6 {
      p {
        margin-left: 0px !important;
      }
    }
  }

  .addProject .paperContainer .buttonContainer {
    display: block;
  }

  .proposalDialogScroll {
    overflow-x: auto;
    margin-bottom: 20px;

    .proposalDialogScrollContainer {
      width: 200px;

      .proposalDialogScrollMobile {
        width: 350px;
      }
    }
  }
}

@media (max-width: 400px) {
  .viewProjectCol {
    .totalCost p {
      font-size: 13px !important;
    }
  }

  .viewProject {
    .main {
      width: 1228px;
    }

    .overviewContainer {
      width: 270px;
    }
  }

  .budget-div {
    .totalCost p {
      font-size: 13px !important;
      line-height: 22px;
    }
  }

  .addProject .paperContainer .MuiStepConnector-horizontal {
    left: -104px !important;
  }
}

@media (max-width: 320px) {
  .buttonContainer .cancel-btn {
    width: 100% !important;
    margin: 0px 0px 10px 0px !important;
    text-align: center;
  }

  .buttonContainer span {
    width: 100% !important;
  }

  .total-cost.geenrateTotal {
    p {
      margin: 12px 10px !important;
    }
  }

  .rfqList {
    overflow-x: auto;

    .MuiTableContainer-root {
      width: 200px;
    }
  }

  .viewInvoiceContainer {
    p {
      margin: 10px 0px !important;
    }

    .subTotalContainer {
      overflow-x: auto;

      .subTotalMobile {
        width: 100px;

        .subTotalMobileScroll {
          width: 250px;
          padding-right: 50px;
        }
      }
    }
  }

  .addProject .paperContainer .MuiStepConnector-horizontal {
    left: -80px !important;
  }

  .stepCostTotal {
    p {
      font-size: 10px;
    }
  }

  .buttonContainer.stepTwoBtnContainer {
    span {
      width: auto !important;
    }
  }

  .stepTwoproposalBtns {
    flex-direction: column-reverse;

    button {
      width: 100% !important;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .privewTable {
    width: 100%;
    overflow: auto;
  }

  .tender-table {
    width: 100%;
    overflow: auto;
  }
}

.tender-table {
  width: 100%;
  overflow: auto;

  .table-body {
    .MuiTableCell-root {
      padding: 6px 15px;

      p {
        margin: 14px 0px;
      }
    }
  }

  tbody.table-body tr {
    display: flex;
    width: 100% !important;
    text-align: left;
    padding: 0px !important;
    margin: 0px;
  }

  tbody.table-body div div {
    width: 100% !important;
    display: flex;
    flex-direction: column;
  }

  tbody.table-body tr td {
    width: 100%;
    text-align: left;
    padding: 2px 2px !important;
  }

  .sub-task .left-img {
    left: 15px;
    position: relative;
  }

  thead.table-head tr th {
    width: 100% !important;
  }

  thead.table-head tr {
    display: flex;
    width: 100% !important;
    text-align: left;
    padding: 0px !important;
    margin: 0px;
  }

  thead {
    background: transparent;
  }

  .drag-img {
    flex-direction: row !important;
    align-items: center;
  }

  // .sub-task td:first-child div p {
  //   text-overflow: ellipsis;
  //   overflow: hidden;
  //   white-space: nowrap;
  //   width: 100px;
  // }
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: #3f51b5;
    // padding: 0px;
  }

  thead.table-head tr th {
    width: 100% !important;
  }

  thead.table-head tr {
    display: flex;
    width: 100% !important;
  }

  tbody.table-body tr {
    display: flex;
    width: 100% !important;
  }

  tbody.table-body div div {
    width: 100% !important;
    display: flex;
    flex-direction: column;
  }

  tbody.table-body tr td {
    width: 100%;
    text-align: left;
  }

  tbody.table-body div div {
    // flex-direction: inherit;
    width: inherit !important;
  }

  // thead.table-head tr {
  //   width: 950px;
  // }
  // thead.table-head tr th {
  //   width: 148px;
  // }
  // tbody.table-body tr {
  //   width: 950px;
  // }
  // tbody.table-body tr td {
  //   width: 148px;
  // }
  .tender-td-padding {
    position: relative;
    left: 20px !important;
  }

  .MuiFormControlLabel-root {
    float: left;
  }

  .drag-img .mat-icon {
    padding-right: 6px;
  }
}

.w-100 {
  width: 100% !important;
}

.p-0-10 {
  padding: 0 10px;
}

.projectManagment .projectCategoryContainer div.selectedFilter {
  background: #6dc9d1 !important;
}

.projectManagment .projectCategoryContainer div.selectedFilter .categoryName {
  color: #fff;
}

.projectManagment .projectCategoryContainer div.normalFilter {
  background: transparent !important;
}

.projectableContainer .privewTable tbody.table-body tr td {
  padding: 23px 3px !important;
  border-bottom: 1px solid #E7E7E7;
}

.projectableContainer .privewTable .MuiTableCell-root {
  border-bottom: 1px solid #E7E7E7;
}

.table-body tr td:first-child {
  text-align: left !important;
}

.projectCategoryContainer {
  margin-top: 30px;
}

.projectManagment .css-ryi1wy-MuiGrid-root .css-12w9cqd-MuiGrid-root {
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-transform: uppercase;
  color: #535353;
  display: flex;
  align-items: center;
}

p.archived-color {
  color: #112cf0;
  background: #ced4ff;
  text-align: center;
  padding: 4px 0;
  width: 80px;
  font-weight: 600;
}

p.cancelled-color {
  color: #ff0000;
  background: #ffc9c9;
  text-align: center;
  padding: 4px 0;
  width: 80px;
  font-weight: 600;
}

p.dlp-color {
  color: #4f5000;
  background: #4c4c3a33;
  text-align: center;
  padding: 4px 0;
  width: 80px;
  font-weight: 600;
}

.addProject .paperContainer .MuiOutlinedInput-inputMarginDense {
  background: unset !important;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ABABAB;
  border-bottom: 1px solid #B7B9BC;
}

div.MuiFormHelperText-marginDense {
  margin-top: 0px;
}

fieldset.MuiOutlinedInput-notchedOutline {
  border-color: unset;
  border-style: unset;
}

span.add-user {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #535353;
}

.cancel-btn.generate-progress-btn {
  width: 100% !important;
}

.MuiTableContainer-root.table-container {
  overflow-x: auto !important;
}

.pan-tool {
  margin-left: -3px;
  margin-right: 3px;
}

.projectOverviewContainer p.categoryName,
.projectOverviewContainer .categoryTotalHeading {
  width: 100%;
  text-align: right !important;
  margin-bottom: 5px !important;
}

.projectOverviewContainer .categoryCard {
  background: #fff;
  box-shadow: 0 0 0.5px;

  .budgetPink {
    background-color: unset !important;
    background: transparent;
  }
}

div.projectOverviewContainer .categoryCard .categoryCount button {
  padding: 4px 4px 1px;
}

.projectOverviewContainer .categoryCard p.categoryCount {
  border-top: 1px solid #ccc;
  padding-top: 8px;
}

.projectOverviewContainer .categoryCard p.categoryCount span {
  font-weight: bold;
}

.projectOverviewContainer .categoryCard .MuiCard-root {
  background: #fff;
}

.projectOverviewContainer .categoryCard .categoryCount button {
  background: #3398dc !important;
}

.projectOverviewContainer .MuiGrid-root.categoryCard {
  border-radius: 1px;
}

.projectOverviewContainer .MuiGrid-root.categoryCard .MuiPaper-rounded {
  border-radius: 1px;
  position: relative;
  box-shadow: 1px 1px 1px 3px #f3f3f3;
}

.bk-icon {
  position: relative;
  top: 0;
  width: 35px !important;
  height: 35px !important;
  line-height: 28px;
  background: #d9ecf8;
  padding: 4px;
  border-radius: 4px;
  display: block;
  margin: auto;
  margin-right: 0;
  text-align: center;
}

.bk-icon img {
  max-width: 100%;
  max-height: 100%;
  padding: 2px;
}

.bk-icon2 {
  background: #dafce8;
}

.bk-icon3 {
  background: #fef8f0;
}

.bk-icon8 {
  background: #dcf5ee;
}

.bk-icon4 {
  background: #fbdfde;
}

.bk-icon5 {
  background: #f4e5fb;
}

.bk-icon6 {
  background: #f7f7e0;
}


.bk-icon7 {
  background: #fde2e9;
}

.viewProject svg.MuiSvgIcon-root.arrow-circle-icon {
  width: 24px;
  height: 24px;
}

@media (max-width: 767px) {

  main .projectOverviewMobile .projectOverviewScroll .projectCategoryContainer,
  main .projectOverviewMobile .projectOverviewScroll .tableContainer {
    width: 100%;
  }

  main .projectOverviewMobile .projectOverviewScroll {
    width: 100%;
  }

  .MuiGrid-container.buttonContainer .MuiGrid-root.MuiGrid-item>span {
    display: block;
    text-align: center;
  }
}

.custom-table-project .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.pan-tool.css-i4bv87-MuiSvgIcon-root {
  width: 20px !important;
  height: 20px !important;
}

// 20/07/22
.MuiGrid-root.MuiGrid-container.templateLabelContainer.css-11lq3yg-MuiGrid-root {
  display: none;
}

.task-details .MuiGrid-container {
  margin-top: 0 !important;
}

.task-details .MuiGrid-container p {
  margin: 10px 0 !important;
}

// .categoryRow .MuiGrid-grid-xs-1{
//   min-width:110px;
//   max-width:110px;
// }
// .categoryRow .MuiGrid-grid-xs-1 span{
//   font-size:35px;
// }
.step-two-custom-btns {
  display: flex;
  justify-content: space-between;
}

.step-two-custom-btns .editprojectStage.cancel-btn {
  margin-left: auto !important;
}



categoryListColMobile .MuiGrid-item.MuiGrid-grid-lg-2 {
  padding: 0 5px !important;
  max-width: 140px;
  flex-basis: unset;
  min-width: 140px;
}

.empty-list {
  max-width: 547px;
  min-width: 547px;
}



.receiveInvoicePO .MuiTableCell-root.MuiTableCell-body,
.receiveInvoicePO .MuiTableCell-root.MuiTableCell-head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
}

.receiveInvoicePO .MuiTableCell-root.MuiTableCell-head {
  min-width: 100px !important;
}

.receiveInvoicePO {
  overflow-x: auto !important;
}

#form-dialog-title+.MuiDialogContent-root {
  overflow-x: hidden !important;
}

.receiveStockContainerMobile .MuiTableCell-root.MuiTableCell-head,
.receiveStockContainerMobile .MuiTableCell-root.MuiTableCell-body,
.viewInvoiceScroll .MuiTableCell-root.MuiTableCell-body,
.viewInvoiceScroll .MuiTableCell-root.MuiTableCell-head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.receiveStockContainerMobile input {
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
  border-radius: 5px;
  padding: 5px 10px !important;
  height: 20px !important;
}

.vendorPaymentMobile,
.vendorPaymentMobileScroll {
  width: auto !important;
}



.addStageContainer .subtrack-row-gap {
  margin-top: 13px !important;
}

.addStageContainer .dropzone {
  padding: 0;
}

.addStageContainer .stage-label-right {
  padding-right: 20px !important;
}

.addStageContainer .addStageContainer p {
  margin: 16px 0;
}

.addStageContainer .stage-label-right-inner {
  margin-right: 20px !important;
}

.addStageContainer .subtrack-row-gap-last {
  margin-top: 30px !important;
}

.progress-fin {
  background: #fff;
  padding: 30px 10px 10px 10px;

  .pro-fin-inner {
    // border: 1px solid #000;
    box-shadow: 1px 1px 1px 3px #f3f3f3;
    border-radius: 5px;
    padding: 10px 25px;
    margin: 0px 7px;
    min-height: 204px;

    .progress-head {
      font-size: 21px;
      font-weight: 500;
    }

    .pro-wrap-inner {
      display: flex;
      justify-content: flex-start;

      .pro-para {
        min-width: 166px;
      }

      .progress-bar-wrapper {
        height: 7px;
        margin-bottom: 7px;
        overflow: hidden;
        background-color: #f5f5f5;
        border-radius: 4px;
        width: 94px;
        margin-top: 9px;
        border: 1px solid #aaa3a3;

        .progress-bar-inner {
          float: left;
          width: 0%;
          height: 100%;
          font-size: 12px;
          line-height: 20px;
          color: #fff;
          text-align: center;
          background-color: #03b2cb;
        }
      }

      // progress{
      //   margin-bottom:15px;
      // }
      //   progress {
      //   border: none;
      //   width: 400px;
      //   height: 10px;
      //   background: crimson;
      // }

      // progress {
      //   color: lightblue;
      // }

      // progress::-moz-progress-bar {
      //   background: lightcolor;
      // }


      // progress::-webkit-progress-bar {
      //   background: green;
      // }

    }

    .pro-para {
      color: #333;
      font-size: 17px;
      margin: 0 0px 15px 0;
      padding-right: 10px;
      text-align: left;
    }
  }
}


// New SCSS
.view-project-page {

  // --------------Progress financial cards-------------------------//
  .progress-cards {
    .card {
      border: 1px solid #F0F0F0;
      border-radius: 5px;
      min-height: 250px;

      .card-header {
        padding: 7px 20px;
        background: #F5F7F8;
        border-radius: 5px 5px 0px 0px;

        h6 {
          text-align: left;
        }
      }

      .card-details {
        padding: 20px;
        text-align: left;

        .progressBar {

          .progress {
            display: flex;
            width: 100%;
            align-items: center;
            margin-bottom: 20px;

            span {
              width: 100%;
              margin-right: 20px;
            }
          }
        }

        .time-spend {
          display: flex;
          justify-content: space-between;
        }

        .financial-text {
          display: flex;
          margin-bottom: 20px;

        }

        .text-bold {
          font-weight: 600 !important;
        }
      }

      &.customer-details {
        p {
          word-break: break-word;
        }
      }
    }
  }

  // -------------Task cards----------------------------------//
  .projectOverviewContainer {
    .MuiGrid-container {
      flex-direction: row !important;
    }

    .categoryCard {
      border: unset;
    }

    .task-cards-section {
      flex-wrap: nowrap;
      overflow: auto;
      max-width: 100%;

      &::-webkit-scrollbar {
        width: 0;
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #80A3B8;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #80A3B8;
      }

      .card {
        background: #FFFFFF;
        border: 1px solid transparent;
        border-radius: 5px;
        transition: all .2s ease-in-out;

        &:hover {

          border: 1px solid #80A3B8;
          transition: all .2s ease-in-out;
        }
      }
    }
  }

  // -----------------------TAsk Table-------------------------------//
  .overviewContainer {
    .main {
      width: 100%;

      .projectableContainer {
        .table-top-row {
          display: flex;
          justify-content: space-between;
          padding: 20px;

          >div {
            text-align: left;
          }

          .task-buttons {
            @media(max-width:36rem){
              width: 100%;
            }
          }

          .buttons {
            display: flex;
            text-align: right;
            justify-content: flex-end;
            flex-wrap: wrap;

            @media(max-width:36rem) {
              text-align: center;
              justify-content: center;
            }

            button {
              width: auto;
              max-height: 40px;
              margin-right: 10px;
              padding: 12px;
              background-color: #40B9C3;
              border-radius: 5px;
              justify-content: center;
              display: flex;
              align-items: center;
              margin-top: 0.5rem !important;

              @media(max-width:36rem) {
                flex: 1 0 20%;
              }

              &:last-child {
                margin: 0;
              }
            }
          }
        }

        .privewTable {
          .first-cell {
            display: flex;
            align-items: center;
            min-width: 530px;
            padding: 20px !important;
          }

          .table-head {
            background: #F5F7F8;

            tr {
              display: table-row !important;
            }

            th {
              padding: 20px;
              text-align: right !important;
              padding-right: 0 !important;
              font-weight: 400 !important;
              font-size: 16px !important;
              line-height: 19px;
              color: #555555 !important;

              &:first-child {
                text-align: left !important;
              }

              &:last-child {
                text-align: center !important;
                min-width: 120px;
              }
            }
          }

          .table-body {
            tr {
              border-bottom: 1px solid #E0E0E0 !important;
              align-items: center;
              display: table-row !important;

              td {
                padding: 20px 0 20px 15px !important;
                color: #202122 !important;
                font-weight: 500;
                text-align: right !important;
                padding-right: 0 !important;
                border: 0 !important;
                font-size: 16px !important;

                &:first-child {
                  text-align: left !important;

                }

                &:last-child {
                  text-align: center !important;
                  min-width: 120px;

                  img {
                    margin-right: 10px;
                  }
                }

                p {
                  color: #202122 !important;
                  font-weight: 500;

                  &.stageTotal {
                    font-weight: 400;
                    padding-left: 20px !important;

                  }
                }

                &.project-stage-name {
                  text-align: left !important;
                  padding: 17px 20px;
                  font-weight: 600 !important;
                  font-size: 16px !important;
                }
              }
            }

            .collapsed-content {
              .sub-content-first-cell {
                text-align: left;
                padding: 15px 20px 15px 53px !important;
                min-width: 530px;
                position: relative;
                text-indent: 50px;
                line-height: 23px;

                img {

                  position: absolute;
                  height: 58px;
                  width: 25px;
                  top: -28px;
                  left: 65px;

                }
              }

              .uncollapsed {
                padding: 0 !important;
              }

              .collapsed {
                padding: inherit !important;
                background: #FAFBFB !important;

                tr {
                  background: #FAFBFB;
                  max-height: 50px;
                  border: 0 !important;

                  td {
                    border: 0 !important;
                  }

                  td {
                    padding: 15px 0 15px 0;
                    font-weight: 500 !important;
                    font-size: 16px;
                    line-height: 19px;
                    color: #202122 !important;
                  }
                }
              }

            }

            .task-name {
              td {
                padding: 17px 16px 17px 19px !important;
              }
            }

            .stage-total-row {
              background: #F5F7F8;
              max-height: 60px;
              min-height: 60px;
            }

          }
        }
      }
    }
  }
}

// NEW SCSS for ADD TASK
.primary {
  .MuiDialog-paperFullWidth {
    min-width: 85% !important;
  }

  .task-name,
  .add textarea {
    border-bottom: 1px solid #B7B9BC;
  }

  input {
    font-weight: 500 !important;
    color: #202122 !important;
  }

  .actionbtn {
    display: flex;
    justify-content: center;

    .footer-btns {
      width: 100%;
      display: flex;
      justify-content: center;

    }
  }

  #form-dialog-title {
    padding: 20px 25px !important;
  }

  .addStageContainer {
    padding-top: 20px;

    table.MuiTable-root {
      thead {
        background-color: #F5F7F8 !important;

        th {
          font-weight: 400;
          font-size: 16px !important;
          line-height: 19px;
          color: #555555 !important;
        }
      }

      tbody {
        tr {
          td {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #202122 !important;

            &.status {
              color: #E9A800 !important;
            }
          }

          img {
            margin-right: 10px;
          }

        }
      }
    }

    .list-title {
      margin: 20px 0;
      background: #FFFFFF;
      border: 1px solid #F0F0F0;
      border-radius: 5px;

      h6 {
        padding: 20px;
      }
    }

    .assign {
      h6 {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;

      }

      .assign-user {
        color: #40B9C3;
        font-weight: 500;
        cursor: pointer;
        font-size: 16px !important;
      }

      .accordion-item {
        margin-top: 30px;
        border: 1px solid #E0E0E0;
        border-radius: 5px;
        padding: 0 !important;
        box-shadow: none !important;
        // .expanded{
        //   display: none;
        // }

        .css-15v22id-MuiAccordionDetails-root {
          padding: 20px 20px 20px 30px !important;

        }

        .accordion-name {
          margin: 0;
        }

        .Mui-expanded .accordion-name {
          display: none !important;
        }

      }

      .user-list-table {
        overflow: auto;
        width: 100%;

        thead {
          background: #F5F7F8;

          th {
            font-size: 16px;
            line-height: 19px;
            color: #555555;
            padding: 20px;
          }
        }

        tbody {
          tr {
            td {
              color: #202122 !important;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;

              &.status {
                color: #E9A800 !important;
              }
            }

            &.subTotal {
              td {
                &:first-child {
                  text-align: right;
                }
              }
            }
          }
        }
      }

      .total-task {
        tr {
          td {
            border: 0;

            &:first-child {
              min-width: 85%;
              width: 85%;
              font-size: 18px;
              line-height: 21px;
              color: #202122 !important;
            }

            &:last-child {
              font-weight: 600;
              font-size: 22px;
              line-height: 26px;
              text-align: right;
              color: #202122 !important;
              text-align: left;
            }
          }
        }
      }

      .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-multiline.css-8q2m5j-MuiInputBase-root-MuiInput-root {
        min-height: 90px;
      }

      .top-inputFields {
        .MuiGrid-root.MuiGrid-container {
          align-items: flex-start;
        }
      }
    }

    .btn-add-item {
      button {
        border: 1px solid #40B9C3;
        border-radius: 5px;
        width: 110px;
        height: 40px;
        display: flex;
        justify-content: center;
        color: #fff !important;
        background: #40B9C3 !important;
        margin: 30px 0px;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
      }
    }

    .accordion-wrapper {
      .dialog-body {
        .top-row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 25px;
        }

        .MuiTextField-root {
          width: 100%;
          padding-right: 15px;
          margin-bottom: 15px;
        }

        .add {
          background: #FAFBFB;
          border: 1px solid #F0F0F0;
          border-radius: 5px;
          padding: 25px 25px 30px 25px;

          .add-btns-row {
            display: flex;
            justify-content: center;

            .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
              border: 1px solid #40B9C3;
              border-radius: 5px;
              width: 110px;
              height: 40px;
              display: flex;
              justify-content: center;
              color: #40B9C3 !important;
              background: transparent !important;
              margin: 0 20px;
              font-weight: 700;
              font-size: 14px;
              line-height: 16px;

            }

            button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-sghohy-MuiButtonBase-root-MuiButton-root {
              border: 1px solid #40B9C3;
              border-radius: 5px;
              width: 110px;
              height: 40px;
              display: flex;
              justify-content: center;
              color: #fff !important;
              background: #40B9C3 !important;
              margin: 0 20px;
              font-weight: 700;
              font-size: 14px;
              line-height: 16px;
            }
          }
        }

      }
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.accordion-item.css-1elwnq4-MuiPaper-root-MuiAccordion-root::before {
      background: none;
    }

    .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content {
      margin: 0 !important;
    }

    .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
      min-height: 0 !important;
      padding: 20px 20px 20px 30px !important;
      width: 100%;
    }

  }
}

@media only screen and (max-width:1600px) {
  .view-project-page {
    .progress-cards {
      .card {
        .card-details {
          padding: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width:1199px) {
  .items-description {
    margin-top: 20px;
  }
}

@media only screen and (max-width:767px) {

  .addStageContainer .assign .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root,
  .addStageContainer .assign .accordion-item .css-15v22id-MuiAccordionDetails-root {
    padding: 10px !important;
  }

  .addStageContainer .list-title h6 {
    padding: 10px !important;
  }

  .actionbtn .footer-btns {
    button {
      max-width: 200px;
      margin: 0 10px !important;
    }
  }

  .accordion-wrapper {
    .dialog-body {
      .add {
        padding: 10px !important;
      }
    }
  }
}

@media only screen and (max-width:576px) {
  .actionbtn .footer-btns {
    flex-direction: column;
    align-items: center;

    button {
      margin: 3px !important;
    }

  }

  .view-project-page {
    .overviewContainer {
      .projectableContainer {
        .table-top-row {
          flex-direction: column;

          .buttons {
            justify-content: flex-start;
          }

          button {
            padding: 5px !important;

          }
        }
      }
    }
  }
}

.top-row-modal {
  display: flex;
  justify-content: space-between;

  .add-btn {
    display: flex;
    justify-content: flex-end;
  }
}

.user-list-table {
  table {
    tr {
      td {
        border-bottom: 1px solid #f0f0f0 !important;
      }
    }

    thead {
      tr {

        td,
        th {
          font-weight: 400;
          font-size: 16px !important;
          line-height: 19px;
          color: #555555 !important;
        }
      }
    }
  }
}

.add-btns-row {
  justify-content: center;

  button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    background: #FFFFFF !important;
    border: 1px solid #40B9C3;
    border-radius: 5px;
    color: #40B9C3;
    width: 110px;
    height: 40px;
  }

  button {
    &:nth-child(2) {
      background: #40B9C3;
      border: 1px solid #40B9C3;
      border-radius: 5px;
      color: #fff;
      width: 110px;
      height: 40px;

    }
  }
}


/*new changes  */
.full-width {
  width: 100%;
  margin-top: 20px !important;
}

.textarea-section .text-area-inner textarea {
  width: 100%;
  border: unset;
  border-bottom: 1px solid #757575;
  resize: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  height: 93px;
  font-weight: 500 !important;
  color: #202122 !important;
}

.file-section-part input {
  display: none;
}


.text-area-inner label {
  margin-top: 17px !important;
  margin-bottom: 5px;
  display: block;
}

.add-task-inner-header-section {
  align-items: self-start;
}

.sub-part,
.textarea-section,
.file-section-part,
.file-list-header {
  padding: 10px;
  height: 220px;
}

.file-section-part .file-part {
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #757575;
  border-radius: 14px;
  box-sizing: border-box;
  flex-direction: column;
  font-size: 15px;
}

label.label-header,
.file-uploade-label {
  margin-top: 17px !important;
  margin-bottom: 5px;
  display: block;
}

ul.list-file li {
  padding: 5px;
  background: #6dc9d1;
  border-radius: 5px;
  margin: 5px 0;
}

ul.list-file {
  padding: unset;
  margin: unset;
  list-style: none;
}

.add-task-inner-header-section {
  margin-bottom: 26px;
}

.file-part label {
  color: #014671;
}

.doc-icon {
  padding-right: 5px;
}

.del-icon {
  display: inline-block;
  float: right;
}

.task-tabs .tabPanel .MuiFormControl-marginDense .MuiOutlinedInput-root {
  background: #f8f8f8;
  border-bottom: 2px solid #757575;
  border-radius: unset;
}

.MuiTabs-flexContainer .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  background: unset !important;
  color: #121111 !important;
  border-bottom: 2px solid #40B9C3;
}

.MuiTabs-flexContainer .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected .MuiTab-wrapper {
  color: #000000 !important;
}

.primary .addStageContainer .accordion-wrapper .dialog-body .MuiTextField-root {
  margin-top: 20px !important;
}

.primary .addStageContainer .accordion-wrapper .dialog-body .MuiTextField-root label {
  top: -23px !important;
}

.MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
  height: 124px;

}

.MuiTabs-flexContainer {
  background: #f4f4f4;
}

.task-tabs .MuiPaper-elevation4 .MuiTab-textColorPrimary {
  background: #f4f4f4;
}

.task-tabs .tabPanel {
  background: #ffff;
}

.task-tabs .tabPanel .MuiFormControl-marginDense .MuiOutlinedInput-root {
  background: #fff;
}

p.cancel-btn.out-line {
  color: #40B9C3 !important;
  background: transparent !important;
  border: 1px solid #40B9C3 !important;
  margin: 0 10px !important;
}

p.cancel-btn.out-line-bg {
  border: 1px solid #40B9C3 !important;
  background: #40B9C3 !important;
}

.task-tabs .tabPanel .MuiFormControl-marginDense .MuiOutlinedInput-root:has(input[type=number]):after {
  content: "";
  position: absolute;
  right: 7px;
  width: 30px;
  // background-image: url('../../../assets/NumbeIcon.png');
  background-position: center;
  height: 100%;
  pointer-events: none;
  background-size: 16px 32px;
  background-repeat: no-repeat;
}

.task-tabs .tabPanel .MuiFormControl-marginDense .MuiOutlinedInput-root input[type=number]::-webkit-outer-spin-button,
.task-tabs .tabPanel .MuiFormControl-marginDense .MuiOutlinedInput-root input[type=number]::-webkit-inner-spin-button {
  opacity: 0;
}

.taskTotal-items {
  display: flex;
  width: unset !important;
  border: 1px solid #E0E0E0;
  flex-wrap: nowrap !important;
}

.taskTotal-items p {
  width: fit-content !important;
  padding: 20px 10px !important;
  padding-left: 10 !important;
  padding-right: 0px !important;
  font-weight: 400;
  font-size: 16px;
  color: #202122;
  white-space: nowrap;
}

.taskTotal-items p:last-child {
  font-weight: 600;
  font-size: 18px;
  font-weight: 600;
  font-size: 18px;
}

.taskTotal {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  border: 1px solid #E0E0E0;

}

.taskTotal-items {
  display: flex;
}

.tabSection {
  align-items: end;
}

.buttonSectionTop {
  padding-left: 9vw;
}


.date-picker .MuiOutlinedInput-inputMarginDense {
  padding: 5px 0;
  border-bottom: 2px solid #757575;
  width: 100% !important;
}

.MuiFormControl-marginDense {
  width: 100%;
}

.date-picker>label {
  display: block;
  padding: 20px 0px 0;
}

.date-picker {
  position: relative;
}

.date-picker:after {
  position: absolute;
  width: 43px;
  height: 56%;
  //background: aqua;
  bottom: 6px;
  content: "";
  right: 0;
  background-position: center;
  background-size: 25px 25px;
  pointer-events: none;
  background-image: url('~/public/calendar.png');
  background-repeat: no-repeat;



}

.sub-part .numder-input {
  margin-top: 40px;
}

.sub-part .numder-input .MuiOutlinedInput-inputMarginDense {
  padding: 5px 0;
  border-bottom: 2px solid #757575;
}

.total-value-text {
  margin-left: auto;
  padding-right: 32px;
  font-weight: 400;
  font-size: 18px;
}

.total-value-text span {
  font-weight: 700;
  padding-left: 8px;
}

.total-value-text {
  margin-left: auto;
  padding-right: 32px;
  font-weight: 400;
  font-size: 18px;
}

.total-value-text span {
  font-weight: 700;
  padding-left: 8px;
}

p.summary-inner span,
.summary-value-total {
  width: fit-content;
  font-weight: 400;
  font-size: 16px;
  color: #202122;
}

span.summary-lable {}

p.summary-inner span span.summary-value {
  font-weight: 600;
  font-size: 18px;
  padding: 0 30px 0px 10px;

}


span.summary-lable-total {
  font-size: 16px;
  font-weight: 500 !important;
}

.subtaskrow-1 .MuiIconButton-label svg path {
  fill: #40B9C3;
}

.label-name {
  color: #40B9C3;
  font-weight: 500;
  cursor: pointer;
  font-size: 16px !important;
}

.overflow-table {
  overflow-x: auto;
}


.addMaterialSection .material-textarea label {
  display: block;
}

.addMaterialSection .material-textarea textarea {
  height: 200px;
  width: 100%;
  border: unset;
  border-bottom: 2px solid #757575 !important;
  resize: none;
  background: transparent;
  font-family: "Roboto", sans-serif !important;
  font-size: 16px;
}

.addMaterialSection input,
.addMaterialSection .MuiSelect-outlined.MuiSelect-outlined {
  border-bottom: 2px solid #757575 !important;
  border-radius: unset;
  padding-bottom: 4px;
  /* padding-top: 10.5px; */
}

.addMaterialSection .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  padding-inline: 0 !important;
}

.addMaterialSection .select-unit-section {
  padding-top: 22px;
}


/* drag and drop */
.projectableContainer {
  .collapsed-content.tabel-lists {
    padding: 0;
    list-style: none;
  }

  ul.tabel-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    border: 1px solid #E0E0E0;
    border-bottom: unset;
    border-top: unset;
  }

  .tabel-list li {
    margin-top: auto;
    min-width: 104px !important;
    font-weight: 500;
    font-size: 16px;
    // color: #202122;
    color: #7a7e94;
    max-width: 104px;
  }

  .tabel-list li:first-child {
    margin-right: auto;
    min-width: 300px !important;
    max-width: 300px;
  }


  ul.tabel-list.adding-type li:last-child {
    width: 143px;
    min-width: 145px !important;
    max-width: 143px;
  }

  ul.tabel-list:first-child {
    height: 60px;
  }

  ul.tabel-list.adding-type {
    padding: 10px 0 10px 20px;
  }

  ul.tabel-list.adding-type li {
    font-weight: 400;
    font-size: 15px;
  }
}

.stage-custom-style {
  text-align: left;
  font-weight: 500;
}

.table-top-row {
  border: 1px solid #E0E0E0;
  padding: 11px 20px !important;
  align-items: center;
}

.view-project-page .overviewContainer .main .projectableContainer .privewTable .table-head li {
  color: #555555 !important;
}

.projectableContainer ul.tabel-list.adding-type li {
  color: #202122;
  font-weight: 600;
  font-size: 16px !important;
  text-transform: capitalize;
}

.projectableContainer ul.tabel-list.adding-type {
  padding: 8px 0 8px 20px;
  border-bottom: 1px solid #E0E0E0;
}

.view-project-page .overviewContainer .main .projectableContainer .privewTable .table-head {
  border: 1px solid #E0E0E0;
}

.view-project-page .overviewContainer .main .projectableContainer .privewTable {
  overflow-y: hidden;
  height: auto !important;
  overflow-x: auto;
}

.projectableContainer .collapsed-content.tabel-lists {
  border: 1px solid #E0E0E0;
}

.projectableContainer .collapsed-content.tabel-lists {
  margin: 0;
}

.view-project-page .overviewContainer .main .projectableContainer .privewTable .table-body .stage-total-row li p {
  color: #000000;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.view-project-page .overviewContainer .main .projectableContainer .privewTable .table-body .stage-total-row li:first-child p {
  color: #555555;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.view-project-page .projectOverviewContainer .task-cards-section {
  flex-wrap: wrap !important;
}

.primary .addStageContainer .assign .user-list-table thead th:nth-child(2) {
  min-width: 200px;
}

.viewProject {
  padding: 20px;
  background: #FFFFFF;
}

.addMaterialSection input,
.addMaterialSection .MuiSelect-outlined.MuiSelect-outlined {
  border-bottom: 1px solid #757575 !important;
}

.viewProject .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
  padding-right: 0px !important;
}

textarea:focus-visible {
  outline: unset;
}

.primary .addStageContainer .assign .user-list-table thead th:is(:nth-child(3), :nth-child(4)) {
  width: 190px !important;
}

.user-list-table table thead tr td:nth-child(4) {
  min-width: 360px !important;
}

/* drag and drop */
.option-custom-style {
  position: relative;
  text-align: left;
  padding-left: 98px;
  font-size: 18px !important;
}

.option-custom-style img {
  position: absolute;
  height: 49px;
  width: 25px;
  top: -35px;
  left: 70px;
}

.option_first_cell {
  display: flex;
  align-items: center;
  max-width: 400px !important;
}

.option-table {
  height: 50px !important;
}

.projectableContainer .collapsed-content.tabel-lists.uncollapsed-list {
  border: 0px;
}

.projectableContainer .collapsed-content.tabel-lists.collapsed-list {
  background: #FAFBFB;
}

ul.collapsed-content.tabel-lists.collapsedlist {
  background: #FAFBFB;
}


.modalContainer:empty {
  display: none;
}

.MuiDialogContent-root .content {
  margin-top: 0 !important;
}

.primary .addStageContainer {
  padding: 0;
}

.addStageContainer p {
  margin: 0;
}

.file-section-part .file-part {
  height: 100px;
}

.sub-part,
.textarea-section,
.file-section-part,
.file-list-header {
  height: 210px;
}

.primary .addStageContainer .list-title h6 {
  padding: 10px;
}

.primary .addStageContainer .accordion-wrapper .dialog-body .top-row {
  margin: 0;
}

.primary .addStageContainer .accordion-wrapper .dialog-body .MuiTextField-root {
  margin-top: 0 !important;
}

.primary .addStageContainer .assign .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-multiline.css-8q2m5j-MuiInputBase-root-MuiInput-root {
  margin-top: 0px !important;
}

.primary .addStageContainer .assign .accordion-item .css-15v22id-MuiAccordionDetails-root {
  padding-top: 0px !important;
}

.Lock-Icon-InputStyle-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profitType-Customstyle {
  position: absolute;
}

.Lock-OpenIcon-InputStyle #outlined-basic {
  color: red !important;
  border-color: red;
}

.Lock-OpenIcon-InputStyle #outlined-basic-label {
  color: red !important;
}

.Lock-Icon-InputStyle-header.sub .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, 6px) scale(0.75) !important;
}

.Lock-Icon-InputStyle-header.sub {
  gap: 0;
}

.Lock-Icon-InputStyle-header.sub .Lock-Icon-InputStyle {
  padding: 0 !important;
}

.Lock-Icon-InputStyle-header-cell {
  display: flex;
  align-items: center;
}

.Lock-Icon-InputStyle-header-cell .Lock-Icon-InputStyle {
  width: 100px;
  padding: 0 !important;
}

.Lock-Icon-InputStyle-header-cell .Lock-Icon-InputStyle .MuiInputBase-root.Mui-disabled {
  padding: 0 I !important;
}

.Lock-Icon-style-subcontractor {
  width: 159px !important;
  margin: 0 !important;
}

.Lock-Icon-InputStyle-header-cell .Lock-Icon-InputStyle {
  margin: 0 !important;
}

.dark-mode .navbar .navbar-brand {
  background: #0e124a;
  z-index: 99;
  box-shadow: -7px 9px 0px 9px #0e124a;
}

.navbar .navbar-brand {
  background: #ffffff;
  z-index: 99;
  box-shadow: -7px 9px 0px 9px #ffffff;
}

.addCustomer .paperContainer .purchaseOrderMaterials div.MuiFormControl-marginDense {
  width: 100%;
}

.purchaseOrderMaterials .assign-user {
  font-size: 20px;
  text-align: left;
  padding: 10px;
  margin-top: 20px;
  background: #f4f4f4;
  color: #45bbc4;
}

.purchaseOrderMaterials .po-material-header {
  margin-bottom: 20px;
  margin-top: 0px;
  text-align: left;
}

.purchaseOrderMaterials .po-material-tablehead {
  background: #f4f4f4;
  font-weight: 800;
}

.purchaseOrderMaterials .user-list-table table thead tr td:nth-child(5) {
  min-width: 360px !important;
}

.purchaseOrderMaterials .user-list-table table thead tr td:nth-child(4) {
  min-width: 125px !important;
}

.purchaseOrderMaterials .user-list-table table thead tr td:nth-child(1) {
  min-width: 250px !important;
}

.subcontractor-saleAmount-style {
  padding: 0px !important;
}


.primary .addStageContainer .accordion-wrapper .dialog-body .user-list-table .MuiTextField-root {
  margin-bottom: 0 !important;
}

.view-project-page .progress-cards .card {
  height: 100%;
}

.view-project-page .projectOverviewContainer .MuiGrid-container {
  align-items: stretch;
}

.attachment-file {
  padding: 0 30px;
}

.attachment-file .dropzone {
  padding: 5px;
}

.work-order-section {
  align-items: self-start;
}

[textalign="end"] {
  text-align: right !important;
}

.add-task-customStyle #outlined-basic {
  border-bottom: 2px solid #757575;
  max-width: 110px;
  min-width: 80px;
}

.tadsSectionList {
  padding-top: 20px;
  padding-bottom: 10px;
}

.tadsSectionList button {
  margin-right: 20px;
  padding: 5px 15px;
  background: #FFFFFF;
  color: #014671;
  border: unset;
  border-radius: 5px 5px 0 0;
  font-size: 18px;
  border: 1px solid #014671;
  border-bottom: unset;
}

.tadsSectionList button.active {
  background: #014671;
  color: #FFFFFF;
}

.primary .addStageContainer .list-title {
  margin-top: 0;
}

.primary .addStageContainer .assign .accordion-item {
  margin-top: 0;
}

/* .addStageContainer .assign .accordion-wrapper{
border: 1px solid  #dadada;
 } */
.addStageContainer {
  .assign .accordion-item {

    border: 1px solid #dadada;
    border-radius: 5px;
    margin-top: 20px !important;


  }

}

.projectableContainer .tabel-list li {
  min-width: 135px !important;
  max-width: 150px;
}

/* .primary .addStageContainer .assign .accordion-item {
  border: unset;
} */


.viewProject-ch {
  padding: 0;

  .projectOverviewContainer {
    padding: 0;
  }

  .progress-fin {
    padding: 0px;

    .MuiGrid-item {
      padding-left: 0;

      &:last-child {
        padding-right: 0;
      }

      @media (max-width: 767px) {
        padding: 0;
      }
    }
  }

  .MuiGrid-spacing-xs-2 {
    width: 100%;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .view-project-page {
    .progress-cards {
      .card {
        border: 1px solid #d6d8da;

        .card-details {
          padding: 8px 15px 0;

          .financial-text {
            margin-bottom: 12px;
          }

          p {
            font-size: 14px;
          }

          .progressBar {
            .progress {
              width: 68%;
              height: 10px;

              span {
                height: 100%;
                background-color: #1976d2;
              }

              p {
                position: absolute;
                right: 10px;
              }

              @media (max-width: 767px) {
                width: 80%;
              }
            }
          }
        }

        .card-header {
          padding: 7px 17px;
          background: #fff;

          h6 {
            font-size: 16px;
            text-transform: uppercase;
            color: #1976d2;
            text-align: left;
          }
        }

        @media (max-width: 767px) {
          min-height: auto;
          margin-bottom: 10px;
          height: auto;
        }
      }
    }

    .projectOverviewContainer {
      .task-cards-section {
        display: flex;
        align-items: flex-start;
        text-align: left;

        .category-card-wrapper {
          padding: 0 !important;
          width: 11.7%;
          display: inline-block;
          margin-right: 12px;

          @media (max-width: 1700px) {
            width: 18.5%;
          }

          @media (min-width: 768px) and (max-width: 1024px) {
            width: 23%;
          }

          @media (max-width: 767px) {
            width: 46%;
          }

          .MuiGrid-grid-xs-3 {
            @media (max-width: 767px) {
              max-width: 25% !important;
              flex-basis: 25% !important;
            }

          }
        }

        .ProjectCard {
          width: 100%;
          min-width: initial;
          min-height: initial;
          margin-left: 0;

          .MuiGrid-container {
            flex-direction: row-reverse !important;
          }

          .task-details {
            margin-left: 0;
            margin-right: 5px;
            text-align: left;

            p {
              margin-top: auto;
              margin-bottom: auto;
            }

            .task-create-text {
              font-size: 10px;
              text-decoration: underline;
              color: #1976d2;
              font-weight: 500;
              cursor: pointer;

              @media (max-width: 1450px) {
                font-size: 10px;
              }
            }
          }

          .task-name {
            font-size: 12px;
            margin-right: 3px;

            @media (max-width: 1450px) {
              font-size: 12px;
            }
          }
        }

        @media (min-width: 1700px) and (max-width: 5000px) {
          flex-wrap: initial !important;
        }

      }

      .categoryCard {
        .MuiCardContent-root {
          padding: 10px 5px !important;
        }

        .MuiGrid-item {
          margin-top: auto;
          margin-bottom: auto;
          text-align: right;
        }
      }
    }

    .overviewContainer {
      .main {
        .projectableContainer {
          .privewTable {
            .first-cell {
              padding: .5rem .5rem !important;
            }

            .table-head {
              background: #fff;
            }

            .css-sghohy-MuiButtonBase-root-MuiButton-root {
              background: #014671 !important;
            }
          }

          ul.tabel-list:first-child {
            height: auto;
          }
        }
      }
    }

    .stage-total-row {
      border-bottom: 1px solid #E0E0E0;
      background: #F3F9FF !important;
      margin-bottom: 15px;

      p,
      h6 {
        color: #014671 !important;
      }

      .option-top-text {
        font-size: 18px !important;
        font-weight: 600 !important;
      }
    }
  }

  .createTask {
    padding: 7px 10px !important;

    .createStageLabel {
      display: flex;
      width: 100% !important;

      svg {
        margin-top: auto !important;
        margin-bottom: auto !important;
      }
    }
  }

  span.stageName {
    width: calc(100% - 50px) !important;
  }

  .viewProject {
    padding: 0;

    .table-top-row {
      margin: 0;

      h6 {
        font-size: 16px;
        text-transform: uppercase;
        color: #1976d2;
        text-align: left;
      }
    }
  }

  .main {
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 100% !important;
    }
  }

}


.allocation-block {
  height: calc(100vh - 120px);
  display: flex;

  // border: 1px solid #ddd;
  .find-allocation {
    height: calc(100% - 44px);
    width: 250px;
    overflow: auto;
    border: 1px solid #ddd;
    margin-top: 55px;

    .find-allocation-header {
      h2 {
        font-size: 16px;
        text-transform: uppercase;
        color: #1976d2;
        text-align: center;
        font-weight: 500;
        margin: 0;
        padding: 10px 0;
        border-bottom: 1px solid #ddd;
      }
    }

    .allocation-list {
      ul {
        padding: 0 15px;
        margin: 0;
        margin-top: 15px;

        li {
          list-style: none;
          margin-top: 10px;
          border-top: 1px solid #ddd;
          padding-top: 10px;
          text-align: left;
          color: #8a8ea5;

          .list-info {
            width: calc(100% - 60px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 15px;
            padding-left: 10px;
          }

          span {
            svg {
              width: 20px;
              height: 20px;
            }
          }

          .active {
            color: #1976d2;

            svg {
              fill: #1976d2;
            }

          }
        }

      }
    }
  }

  .schedule-allocation {

    width: calc(100% - 250px);

    .schedule-allocation-header {
      h2 {
        font-size: 16px;
        color: #000;
        font-weight: 500;
      }

      .datepicker-block {
        .input-block .input-icon {
          top: 8px;
        }

        .MuiInputBase-input {
          padding-top: 10px !important;
        }
      }
    }

    .schedule-allocation-block {
      border: 1px solid #ddd;
      border-left: 0;
      overflow: auto;
      height: calc(100% - 43px);
    }

    .table.table-material {
      table-layout: fixed;

      thead {
        tr {
          th {
            height: 40px;
            width: 200px;
            text-align: center;
            color: #000;
            border-right: 1px solid #e5e5e5;

            &:first-child {
              position: sticky;
              left: 0;
              padding: 0;

              span {
                display: block;
                height: 100%;
                border-bottom: 1px solid #e5e5e5;
                border-right: 1px solid #e5e5e5;
                padding: .5rem .5rem;
              }
            }
          }
        }
      }

      td {
        width: 200px;
        height: 40px;
        border-right: 1px solid #e5e5e5;
        padding: 2px 4px;

        &:first-child {
          position: sticky;
          left: 0;
          padding: 0;
          background: #fff;
          z-index: 2;

          p {
            padding: 2px 4px;
            border-bottom: 1px solid #e5e5e5;
            border-right: 1px solid #e5e5e5;
            height: 100%;

          }
        }

        &.selectable-block {
          width: auto;
          height: auto;
          border-right: 0px solid #e5e5e5;
          padding: 0;

          tr {
            td {
              &:first-child {
                position: initial;
                left: 0;
                padding: 2px 4px;
                background: #fff;
                z-index: 1;
                border-right: 1px solid #e5e5e5;

                p {
                  padding: 4px 5px;
                  border-bottom: 0px solid #e5e5e5;
                  border-right: 0px solid #e5e5e5;
                  height: auto;
                }
              }

            }
          }

        }

        &.selected-td {
          border: 1px solid #9dd4d9 !important;
          background: #e9fdff !important;
        }
      }
    }

    .material-list-block {
      border: none;
    }

    .datepicker-block {
      .input-icon {
        top: 8px;
      }
    }
  }

  .timeinfo {
    margin: 0;
    color: #565656;
    position: relative;
    display: flex;

    span {
      svg {
        width: 15px;
        height: 15px;
      }
    }

    &.active {
      color: #1976d2;

      svg {
        fill: #1976d2;
      }

    }

    .closeicon {
      position: absolute;
      right: 8px;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 15px;

      svg {
        fill: #d0021b;
      }
    }

    .usericon {
      position: relative;
      top: -1px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .tablestatus {

    border-radius: 4px;
    font-size: 10px;
    padding: 4px 5px;
    text-align: center;
    display: flex;
    font-weight: 500;

    &.available {
      background: #aff1c3;
      color: #316d48;
    }

    &.partial-available {
      background: #fff9b8;
      color: #c3883b;
    }

    &.not-available {
      background: #ffd9d9;
      color: #9c3d44;
    }

    &.booked {
      background: #5e9fdf;
      color: #fff;
    }
  }

  @media (max-width: 1400px) {
    height: auto;
    display: block;

    .find-allocation {
      height: auto;
      width: 100%;
      overflow: initial;
      margin-top: 0;
      margin-bottom: 15px;

      .find-allocation-form {
        width: 100%;
        display: flex;
        vertical-align: top;
        padding-bottom: 15px;
        border-bottom: 1px solid #ddd;
      }

      .allocation-list {
        width: 100%;
        display: block;
        height: auto;
        overflow: auto;
        text-align: left;

        ul {
          text-align: left;
          display: inline-flex;
          flex-wrap: nowrap;
          flex-direction: row;
          margin-bottom: 10px;

          li {
            width: 200px;
            padding: 0;
            display: inline-block;
            vertical-align: top;
            border: 1px solid #ddd;
            padding: 7px 5px;
            border-radius: 5px;
            margin-right: 5px;
            margin-top: 0;
            margin-bottom: 5px;

            .list-info {
              font-size: 13px;
            }

            span {
              svg {
                width: 17px;
                height: 17px;
              }

              &.list-action-icon {
                svg {
                  width: 15px;
                  height: 15px;
                }
              }
            }
          }
        }
      }

      .allocation-search-btn {
        margin-top: auto !important;

        .btn {
          margin-left: initial !important;
        }
      }
    }

    .schedule-allocation {
      width: 100%;

      .schedule-allocation-block {
        height: auto;
        border-left: 1px solid #ddd;

        .table.table-material {
          table-layout: fixed;
          width: 100%;

          thead {
            tr {
              th {
                width: 115px;
              }
            }
          }

          td {
            width: 115px;
            height: 50px;
          }
        }
      }
    }

    .timeinfo {
      font-size: 12px;

      span {
        svg {
          width: 12px;
          height: 12px;
        }
      }

    }

    .tablestatus {
      display: block;
      text-align: left;
      font-size: 9px;
    }

  }

  @media (max-width: 1024px) {
    .find-allocation {
      width: 100%;

      .find-allocation-form {
        width: 100%;
      }

      .allocation-list {
        width: 100%;
        max-height: 400px;

        ul {
          display: block;

          li {
            width: 100%;
          }
        }
      }

      .allocation-search-btn {
        margin-top: 15px !important;

        .btn {
          margin-left: auto !important;
        }
      }
    }

    .schedule-allocation {
      width: 100%;

      .schedule-allocation-header {
        h2 {
          font-size: 14px;
        }
      }

      .material-list-block {
        border: 1px solid #ddd;
      }

      .schedule-allocation-block {
        height: auto;
        border: 1px solid #ddd;

        .table.table-material {
          table-layout: fixed;
          width: 100%;

          thead {
            tr {
              th {
                width: 115px;
              }
            }
          }

          td {
            width: 115px;

          }
        }
      }

    }

    .timeinfo {
      .usericon {
        margin-top: auto !important;
        margin-bottom: auto !important;
      }

      .closeicon {
        position: absolute;
        right: 8px;
        top: 0;
        bottom: 0;
        margin: auto !important;
        height: 15px;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .find-allocation {
      .allocation-list {
        width: 100%;
        max-height: initial;

        ul {
          display: inline-flex;
          flex-wrap: nowrap;
          flex-direction: row;
          margin-bottom: 10px;

          li {
            width: 200px;
          }
        }
      }
    }

    .schedule-allocation {
      .schedule-allocation-block {
        .table.table-material td {
          height: 50px;
        }
      }

      .schedule-allocation-header {
        .btn {
          padding: 5px 8px !important;
        }
      }

    }
  }

  @media (max-width: 767px) {
    .go-btn {
      margin-top: 15px !important;
      margin-bottom: 15px !important;
    }

    .schedule-allocation-header {
      display: block !important;

      .btn {
        padding: 5px 8px !important;
      }
    }
  }
}

.modal-top-header-info {
  position: relative;

  .header-content {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
  }

  h2 {
    font-size: 18px;
    text-transform: uppercase;
    color: #1976d2;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  .btn-close {
    position: absolute;
    top: -12px;
    bottom: 0;
    margin: auto !important;
    right: 12px;
  }
}

.allocation-table-status {
  width: 25px;
  height: 24px;
  display: inline-block;
  border-radius: 3px;
  text-align: center;
  line-height: 25px;

  &.leave {
    color: #316d48;
    background: #aff1c3;
  }

  &.conflicts {
    color: #987638;
    background: #fff9b8;
    cursor: pointer;
  }
}

.project-task-dropdown {
  width: calc(100% - 220px) !important;

  @media (max-width: 767px) {
    width: 100% !important;
    margin-bottom: 15px;
  }
}