.customerManagement{
    .customerCategoryContainer{
        .totalProjects{
            color: black;
        }
        .currentProjects{
            .categoryCount {
                color: #2BAA0E;
            }
        }
        .dlpProjects{
            color: yellowgreen;
        }
        .lostProjects{
            color: purple;
        }
        .completedProjects{
            color: #D0021B;
        }
        .cancelledProjects{
            color: red;
        }
        
    }
    .tableContainer{
        margin-top: 15px;
    }
    .headingContainer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .button{
        background: #014671;
        font-size: 11px;
        color: white;
        margin-left: 10px;
        box-shadow: none;
        border: 1px solid #014671;
    }
    .button:hover {
        background: transparent;
        color: #014671;
    }
    button.user-role-btn {
        background-color: #fff;
        color: #014671;
    }
    
}

.createBtn {
    margin: 20px !important;
    background-color: #014671 !important;
    text-transform: unset !important;
}
.addCustomer{
    .paperContainer{
        min-height: auto;
        height: auto;
        padding: 20px;
        .MuiGrid-spacing-xs-4 {
            margin: 0px;
        }
        .businessInfo {
            display: unset;
            text-align: left;
            .delete-icon {
                color: #f44336;
            }
            
            .MuiIconButton-root {
                padding: 0px 10px 0px 0px;
            }
            .MuiIconButton-root:hover {
                background: none;
            }
        }
        .businessInfo, .pointInfo {
            font-size: 16px;
            color: #707070;
            padding: 0px 0px;
            p{
                color: #707070;
                font-size: 14px;
                cursor: inherit;
            }
            .MuiGrid-container {
                margin-bottom: 0px;
            }
        }
        .label{
            font-size: 14px;
            margin-top: 10px;
            text-align: left;
            color: #000;
            display: flex;
            align-items: center;
        }
        .addNewRoleCol {
            display: flex;
            .addNewRole{
                width: 60%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                color: #014671;
                font-weight: 600;
                font-size: 15px;
            }
            .no-site-address {
                margin-left: 10px;
                color: #014671;
                font-weight: 400;
                font-size: 15px;
            }
        }
        .MuiGrid-item {
            text-align: left;
            margin: 0px;
        }
        .modify {
            text-align: left;
            margin: 13px 0px;
            font-size: 17px;
            font-weight: 700;
            color: #014671!important;
            text-decoration: none!important;
            cursor: pointer;
        }
        .modify:hover {
            color: #000!important;
        }
        .MuiOutlinedInput-inputMarginDense {
            background: #F4F4F4;
            font-size: 14px;
        }
        .MuiFormControl-marginDense {
            width: 100%;        
            .MuiInputBase-formControl {
                margin-bottom: 6px;
            }    
        }
        .button{
            display: block;
            background: #B2C7D4;
            font-size: 11px;
            color: white;
            box-shadow: none;
            border: 2px solid #B2C7D4;
        }
        .button:hover {
            background-color: #014671;
            color: #fff;
            border: 2px solid #014671;
        }
        
        p:hover{
            cursor: pointer;
        }
        
        .modify{
            text-align: left;
            margin: 13px 0px;
            font-size: 17px;
            font-weight: 700;
            color: blue;
            text-decoration: underline;
            cursor: pointer;
        }
        .addNewRole, .removeNewRole {
            width: auto;
            float: left;
            font-size: 16px;
            font-weight: 600;
            margin: 20px 0 0;
            cursor: pointer;
            text-align: left;
        }
        .removeNewRole {
            color: #014671!important;
        }
       .removeNewRole:hover {
            color: #000!important;
            text-decoration: underline;
        }
         .removeNewRole {
            float: right;
            text-align: right;
            margin-right: 54px;
        }
        .addNewRoleCol {
            .MuiTypography-body1{
                color: #333333;
                font-size: 14px;
            }
        }
        
    }
    
    .topNavigation{
        text-align: left;
        margin: 10px 0 20px;
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        a{
            text-decoration: none;
            color: #014671;
        }
        a:hover{
            cursor: pointer;
        }
    }
}
.buttonContainer{
    justify-content: flex-end;    
    border-top: 1px solid #e0e0e0;
    margin: 30px 0 0 0; 
    padding: 20px 0 0 0;
}

.addressContaier {
    margin: 0px!important;
}

.address-contaier {
    margin: 0px!important;
    textarea { 
        padding: 10.5px 14px!important;
    }
}
.contact-margin{
    margin-bottom: 15px !important;
}
.MuiGrid-root.categoryCard.MuiGrid-item {
    margin-left: 8px;
}
.customerCategoryContainer {
    margin-bottom: 20px;
    position: relative;
    margin-top: 40px;
}
table.MuiTable-root thead th div {
    white-space: nowrap;
}
.table td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft {
    line-height: 20px;
    padding-bottom: 5px!important;
    padding-top: 5px!important;
    font-size: 14px!important;
    vertical-align: middle;
}
.absolute-user-part.aup-customer .absolute-user-icon {
    background: radial-gradient(50% 50% at 50% 50%, #00B112 0%, #009B10 100%);
}
.categoryCard .categoryName, .categoryCard .categoryCount {
    text-align: right;
}
.totalCount {
    width: 280px;
}
.absolute-user-part.aup-customer {
    top: -28px;
}
p.categoryName {
    font-weight: normal;
    font-size: 18px!important;
    line-height: 23px;
    text-align: center;
    color: #3C4858;
    margin-top: 0;
}
button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    background-color: #6DC9D1 !important;
    border-radius: 5px !important;
    color: #fff;
    border: 0;
}
.MuiTable-root{
 .MuiTableCell-root {
    vertical-align: middle;
    white-space:pre;
}
 .MuiButtonBase-root.Mui-disabled {
    font-size: 15px;
}
}
.addCustomer .paperContainer .addNewRole, .addCustomer .paperContainer .removeNewRole {
    padding: 10px 12px;
    color: #fff;
}
.addCustomer .paperContainer .addNewRole:hover, .addCustomer .paperContainer .removeNewRole:hover {
    color: #fff;
    text-decoration: unset;
}
body main  div.paperContainer .businessInfo p, body main div.paperContainer .pointInfo p {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    color: #535353;
    width: fit-content!important;
    text-align: left!important;
    float: none!important;
    padding-left: 0;
    margin-bottom: 10px!important;
}
body main  div.paperContainer .businessInfo p.removeNewRole{
    color: #fff;
}
.float-content-right {
    text-align: right;
}
div.buttonContainer {
    border-top: 0;
    .MuiGrid-root.MuiGrid-item {
        text-align: center;
    }
}
@media (max-width: 767px) {
.addCustomer .paperContainer .addNewRole, .addCustomer .paperContainer .removeNewRole {
    font-size: 14px;
}
}