.viewPO{
    .topNavigation{
        text-align: left;
        margin: 10px 0 20px;
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        a{
            text-decoration: none;
            color: #014671;
        }
        a:hover{
            cursor: pointer;
        }
    }
    .paperContainer{
        padding: 15px 25px;
        .viewPoInfo{
            text-align: left;
        }
    }
    .listHeader{
        background: #F4F4F4 ;
        border-radius: 4px ;
        padding: 0 15px ;
        p{
            font-weight: bold;
            text-align: left;
        }
    }
    .poListRow {
        padding: 0 15px;
        p {
            text-align: left;
        }
    }
    .poListRow:nth-child(odd) {
        background: #f8f8f8;
    }
    .poListRow:nth-child(even) {
        background: #fcfcfc;
    }
    .evenBackground{
        background-color: #fcfcfc;
        padding: 0 15px;
        align-items: center;
    }
    .oddBackground{
        background-color: #F4F4F4;
        padding: 0 15px;
        align-items: center;
    }

}
.createPoContainer{
    text-align: left;
    .topNavigation{
        text-align: left;
        margin: 10px 0 20px;
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        a{
            text-decoration: none;
            color: #014671;
        }
        a:hover{
            cursor: pointer;
        }
    }
    .paperContainer{
        padding: 20px;
        min-height: 80vh;
        height: auto;
    }
    .submitQuote {
        color: #014671;
        cursor: pointer;
    }
    .reviewQuote {
        color: #f49545;
        cursor: pointer;
    }
    .reviewQuote:hover,
    .submitQuote:hover,
    .viewPO:hover {
        text-decoration: underline;
        color: #333333;
    }
    .viewPO {
        color: #014671;
        cursor: pointer;
        text-decoration: underline;
    }
    .listHeader{
        background: #F4F4F4 ;
        border-radius: 4px ;
        padding: 0 15px ;
    }
    .evenBackground{
        background-color: #fcfcfc;
        padding: 0 15px;
        align-items: center;
    }
    .oddBackground{
        background-color: #F4F4F4;
        padding: 0 15px;
        align-items: center;
    }
}

.add-material { 
    tbody {
        tr {
            vertical-align: top;
            td, .MuiSelect-selectMenu  { 
                font-size: 14px;
            }
        }
    }
}

.headingMargin{
    margin-bottom: 10px !important;
}

.userManagment .userCategoryContainer .MuiGrid-root {
    width: calc(100% + 0px);
}

.tableContainer {margin-top: 10px !important;}


