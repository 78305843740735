.userManagment{
    .userCategoryContainer{
        .totalProjects{
            color: black;
        }
        .activeUser{
            color: green;
        }
        .inactiveUser{
            color: red;
        }
        .selectedFilter {
            background: #fff !important;
            cursor: pointer;
        }
        .normalFilter {
            background: #f6f6f6 !important;
            cursor: pointer;
        }
        
    }
    .tableContainer{
        margin-top: 15px;
        .toolbar-filter {
            display: flex;
            margin-left: 20%;
            label {
                margin-top: 10px;
                margin-right: 5px;
            }
            .MuiFormControl-root {
                width: 20%;
            }
        }
    }
    .headingContainer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .button{
        background: #014671;
        font-size: 11px;
        color: white;
        margin-left: 10px;
        box-shadow: none;
        border: 1px solid #014671;
    }
    .button:hover {
        background: transparent;
        color: #014671;
    }
    button.user-role-btn {
        background-color: transparent;
        color: #014671;
    }
    .userOverviewText{
        font-size: 16px;
        font-weight: 400;
    }
}
.addUser{
    .paperContainer{
        min-height: auto;
        height: auto;
        padding: 20px;
        
        .label{
            font-size: 14px;
            margin-top: 10px;
            text-align: left;
            color: #333333;
            display: flex;
            align-items: center;
        }
        .addNewRoleCol {
            display: flex;
            .addNewRole{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;
                color: #014671;
                font-weight: 400;
                font-size: 14px;
                .or {
                    display: inline-block;
                    margin: 0px 0 0px 30px;
                    color: #333333;
                }
            }
            .addNewRole:hover {
                color: #000!important;
            }
        }
        .MuiGrid-item {
            text-align: left;
            margin: 0px;
        }
        .modify {
            text-align: left;
            margin: 13px 0px;
            font-size: 14px;
            font-weight: 700;
            color: #014671!important;
            text-decoration: none!important;
            cursor: pointer;
        }
        .modify:hover {
            color: #000!important;
        }
        .MuiOutlinedInput-inputMarginDense {
            background: #F4F4F4;
            font-size: 14px;
        }
        .MuiFormControl-marginDense {
            width: 100%;            
        }
        .button{
            display: block;
            background: #B2C7D4;
            font-size: 11px;
            color: white;
            box-shadow: none;
            border: 2px solid #B2C7D4;
        }
        .button:hover {
            background-color: #014671;
            color: #fff;
            border: 2px solid #014671;
        }
       
        p:hover{
            cursor: pointer;
        }
        .buttonContainer{
           justify-content: flex-end; 

        }
        .modify{
            text-align: left;
            margin: 13px 0px;
            font-size: 14px;
            font-weight: 400;
            color: blue;
            text-decoration: underline;
            cursor: pointer;
            img {
                margin: 0px 10px -3px 0;
            }
        }

    }
    

}
.newUser {
    .UserNameCol {
        margin-bottom: 10px;
        .selectUserRole {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #333333;
        }
        .MuiFormControl-marginDense {
            width: 100%;
            background: #f3f3f3;
        }
        .MuiFormControl-root {
            background-color: white;
        }
    }

    .MuiFormControl-root.cs_radio {
        .MuiFormLabel-root {
            font-size: 14px;
            color: #333;
        }
    }
    table {
        width: 100%;
        .MuiSwitch-thumb {
            background-color: #fff;
        }
        .MuiSwitch-track {
            opacity: 1;
            height: 15px;
            margin-top: 1px;
        }
        tr:first-child {
            line-height: 40px;
        }
    }
    .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
        background-color: #ec684a;
        opacity: 1;
    }
}

.createBtn {
    margin: 0px!important;
    background-color: #014671 !important;
    text-transform: unset !important;
}
.AddNewCustomerIcon {
    color: #014671!important;
    padding: 0px 10px;
        .MuiSvgIcon-root {
            width: 18px;
            height: 18px;   
        }
 }

.timesheet-filled-present {
    .MuiPickersDay-day {
        background-color: lightgreen;
    }
}

.timesheet-filled-leave {
    .MuiPickersDay-day {
        background-color: crimson !important;
        color:white !important
    }
}

.apply-leave-timesheet {
    .MuiPickersDay-daySelected {
        background-color: white !important;
        color: rgba(0, 0, 0, 0.87) !important
    }
}

.userRoleListCol {
    .MuiToolbar-gutters {
        padding: 0px;
    }
    table {
        width: 100%!important;
    }
    .editIcon {
        margin-right: 5px;
    }
}

.allocateUserForm {
    .MuiGrid-container {
        display: flex;
        align-items: center;
    }
    .MuiFormControl-marginDense {
        width: 100%;
    }
    .MuiInputBase-inputMarginDense {
        background: #F4F4F4;
        font-size: 14px;
    }
}

.userleave {
    .MuiFormControl-marginDense {
        width: 100%;
    }
    .MuiInputBase-marginDense {
        width: 100%;
        background: #f4f4f4;
        font-size: 14px;
    }
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #ec684a!important;
}