.budget-container{
    .button{
        background: #014671;
        font-size: 11px;
        color: white;
        margin-left: 10px;
        box-shadow: none;
        border: 1px solid #014671;
        float: right;
    }
    .topNavigation{
        text-align: left;
        margin: 10px 0 20px;
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        a{
            text-decoration: none;
            color: #014671;
        }
        a:hover{
            cursor: pointer;
        }
        .btnPrimary {
            margin-top: -12px!important;
        }
    }
    .budget-navigator{
        display: flex;
        justify-content: space-between;
        .btnPrimary { margin-top: -12px!important; }
    }
    .budget-div{
        padding: 20px!important;
        .MuiInputBase-input{
                        border:1px solid #ddd !important;
                        padding: 10px !important;
                    }
        .stage-name {
            font-size: 20px;
            text-align: left;
        }
        .MuiTableCell-root {
            padding: 0 13px!important;
            height: 48px!important;
            border: 0px;
        }
        .MuiTableRow-head {
            height: 53px!important;
        }
        .retention-input-style input{
            border:1px solid #ddd!important;
        }
        .task-accordion {
            border-radius: 5px;
            margin: 20px 0px;
            .accordion-action-checkbox {
                color: #014671 !important;
            }
            .MuiButtonBase-root.MuiAccordionSummary-root {
                max-height: 30px;
            }
            .MuiFormControlLabel-label {
                display: flex;
                .MuiIconButton-root.info-icon {
                    color: #014671 !important;
                }
            }
            .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
                background-color: rgba(1,70,113, 0.2) !important;
                max-height: 30px;
            }
            .MuiAccordionSummary-expandIcon {
                color: #014671 !important;
            }
            
            .MuiAccordionDetails-root {
                flex-direction: column;
                .data-table {
                    .table-container {
                        margin: 10px 0px 20px;
                        border-radius: 2px;

                        .table-head {
                            background-color: rgba(220,220,220, 0.5);
                        }
                        .even-row {
                            background-color: rgba(220,220,220, 0.5);
                        }
                    }
                }
                .total-cost {
                    background-color: rgba(220,220,220, 0.5);
                    width: 25%;
                    border-radius: 2px;
                    margin: auto;
                   

                }
            }
        }
        .data-table {
            .table-container {
                margin: 10px 0px 20px;
                border-radius: 2px;
                  width: 100%;
                .budget-under {
                    color: lightgreen !important;
                }
                .budget-exceed {
                    color: #ff6a2e !important;
                }
                .table-checkbox {
                    color: #014671 !important;
                }
                .table-head {
                    background-color: rgba(220,220,220, 0.5);
                }
                .even-row {
                    background-color: rgba(220,220,220, 0.5);
                }
            }
        }
        .total-cost {
            background-color: rgba(220,220,220, 0.5);
            width: 30%;
            border-radius: 2px;
            margin: auto;
             padding-right: 15px !important;
        }
        .MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeSmall, .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeSmall {
          padding: 0px !important;
        }
    }
}

.allocated-budget-tooltip {
    border-radius: 2px !important;
    color: black !important;
    font-size: 12px !important;
    padding: 5px !important;
    border: black !important;
    border-width: 4px !important;
}

.budget-retention {
    .button-row {
        display: flex;
        .table-button{
            background: #014671;
            font-size: 9px;
            color: white;
            box-shadow: none;
            border: 1px solid #014671;
            float: right;
            margin: 2px;
        }
    }
}
.custom-invoice-form{
    padding:10px;
    width:50% !important;
    .invoice-label,.due-label{
        font-weight:600;
        text-align:left;
        margin:12px 20px !important;
    }
    .subtaskrow-1{
        display:flex;
        justify-content:space-between;

    }
}
.budget-total td {
    background: #f3f9ff !important;
    color: #014671 !important;
}
.budget-under {
    color: lightgreen !important;
}
.budget-exceed {
    color: #ff6a2e !important;
}