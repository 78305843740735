.template-container{
    .template-heading{
        min-height: 40px;
        padding: 0px 0px 0px 20px;
        text-align: left;
        .template-heading-text{
            font-size: 16px;
            font-weight: 400;
            color: #121212;
        }
    }
    .template-category-cards{
        .categoryName{
            text-align: left;
            color: gray;
            font-size: 16px;
        }
        .categoryCount{
            font-weight: 600;
            text-align: left;
            font-size: 20px;
            color: inherit;
            margin: 0;
        }
        .categoryCard{
            .MuiCardContent-root{
                width: 100%;
                height: 99px;
            }
        }
        .selectedFilter{
            background: #fff !important;
            cursor: pointer;
        }
        .normalFilter{
            background: transparent !important;
            cursor: pointer;
        }
    }
    .template-edit-container {
        background-color: #fff;
        margin-top: 8px;
        .template-select {
            .MuiFormControl-root {
                display: flex;
                margin: 40px;
                width: 20%;
            }
        }
        .edit-form-element {
            display: flex;
            flex-direction: column;
            text-align: left;
            margin: 20px;
        }
    }
    .finance-container {
        background-color: #fff;
        margin-top: 8px;
        padding: 20px;
        .edit-form-element {
            display: flex;
            flex-direction: column;
            text-align: left;
            margin: 20px;
        }
    }
    .letterhead-container {
        background-color: #fff;
        margin-top: 8px;
        padding: 20px;
        .subtaskrow-1{
            border-bottom:1px solid #e7e7e7;
        }
        .edit-form-element {
            display: flex;
            flex-direction: column;
            text-align: left;
            margin: 20px;
        }
        .letterhead-setting-logo {
            display: flex;
            align-items: center;
            img {
                width: 150px;
            }
           
        }
        .label{
            font-size: 14px;
            margin-top: 10px;
            text-align: left;
            color: #000;
            display: flex;
            align-items: center;
        }
        .addNewRoleCol {
            display: flex;
            .addNewRole{
                width: 60%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                color: #014671;
                font-weight: 600;
                font-size: 15px;
            }
            .no-site-address {
                margin-left: 10px;
                color: #014671;
                font-weight: 400;
                font-size: 15px;
            }
        }
        .MuiGrid-item {
            text-align: left;
            margin: 0px;
        }
    }
}
main .template-container .template-category-cards .selectedFilter {
    background: #40B9C3 !important;
    border-radius: 5px;
}
.MuiGrid-root.MuiGrid-container.css-11lq3yg-MuiGrid-root {
    width: 100%;
}
.MuiGrid-container {
    align-items: center;
}
.cancel-btn.download-button {
    width: 60px!important;
    min-width: 60px!important;
    background: #ac4994!important;
    border: 1px solid #ac4994 !important;
    height: 40px;
}
.template-container .MuiGrid-root.MuiGrid-container.css-11lq3yg-MuiGrid-root {
    background: #fff;
    margin-bottom: 10px;
}
.template-container .efe-pnt .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
    background: unset;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #ABABAB;
    border-bottom: 1px solid #B7B9BC;
    margin-bottom: 20px;
}
.custom-input-finance .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-marginDense.MuiOutlinedInput-marginDense{
    border-radius:0px !important;
}
.template-container .finance-container .edit-form-element.efe-pnt {
    flex-direction: row;
}
.template-container .letterhead-container div.edit-form-element {
     flex-direction: row;
}

@media (max-width: 767px) {
.template-container .letterhead-container .letterhead-setting-logo {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 10px;
    img{
        margin: 10px 0;
    }
}
.letterhead-container .MuiGrid-container {
    align-items: flex-start;
}
button.MuiButtonBase-root.MuiFab-root.makeStyles-button-29.cancel-btn.download-button.MuiFab-sizeSmall.MuiFab-secondary {
    height: 37px;
    width: 50px!important;
    min-width: 50px!important;
}
.cancel-btn.download-button .icon .MuiSvgIcon-root {
    width: 20px;
    height: auto;
}
.template-container .template-edit-container .MuiGrid-container {
    flex-direction: column;
    align-items: flex-start;
}
.template-container .template-edit-container .MuiGrid-container .MuiGrid-root.label.MuiGrid-item.MuiGrid-grid-xs-4, 
.template-edit-container .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-7, .MuiGrid-root.subtaskrow-1.MuiGrid-item.MuiGrid-grid-xs-6 {
    max-width: 100%;
    flex-basis: 100%;
    width: 100%;
    text-align: left;
}
.user-management-tab .css-z5u3mf-MuiGrid-root .MuiButton-outlinedSizeMedium:last-child {
    padding: 9px 0;
}
div.user-management-tab .css-z5u3mf-MuiGrid-root button.MuiButton-outlinedSizeMedium:first-child {
    padding: 8px 0;
}
div.user-management-tab .css-z5u3mf-MuiGrid-root .MuiButton-outlinedSizeMedium:last-child, div.user-management-tab .css-z5u3mf-MuiGrid-root .MuiButton-outlinedSizeMedium{
    font-size: 13px;
}
.absolute-user-part {
    top: -12px;
}
.userManagment .userCategoryContainer .MuiGrid-root.categoryCard  {
    margin-top: 0;
    margin-bottom: 0;
}
body {
    background-color: #ffffff!important;
}
.letterhead-container .MuiGrid-grid-xs-4 {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
}
}
.addNewRoleCol.custom-input-finance .MuiFormControl-marginDense{
    width:90% !important;
    margin:auto;
}