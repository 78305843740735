.login{
    display: flex;
    height: 100vh;
    background: #fff;;
    justify-content: center;
    align-items: center;
    width: 100%;
     background-image:url('../../../assets/bg_image.png');
    .logo{
        flex: 1 1;
        font-size: 45px;
        font-weight: 600;
        font-family: sans-serif;
        color: darkblue;
        height: 248px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 2px solid #ccc
    }
    .loginForm{
        flex: 1;
        .loginFormStart {
            width: 100%;
            margin: 0 auto;
        }
        .MuiInputBase-input {
            border-bottom: 1px solid #B7B9BC;
            padding-left: 0;
        }
        p.LoginHeading{
            font-size: 24px;
            color: #121212;
            font-weight: 500;
            margin-top: 0px;
            margin-bottom: 30px;
        }
        label.LoginLabel {
            font-size: 14px;
            color: #333333;
            width: 100%;
            display: block;
            text-align: left;
            padding-bottom: 5px;
        }
        // .MuiOutlinedInput-root {
        //     margin-bottom: 15px;
        // }
        .MuiFormControl-marginDense {
            width: 100%;
            margin: 0;
        }
        .ForgotDiv {
            text-align: left;
            cursor: pointer;
            .MuiSvgIcon-root {
                width: 16px;
                height: 16px;
            }
            .PrivateSwitchBase-root-10 {
                padding: 0 8px 0 20px;
            }
            .MuiTypography-body1 {
                font-size: 14px;
            }
            p{
                font-size: 14px;
                display: inline-block;
                margin: 0px;
                color: #014671;
                float: right;
                line-height: 25px;
                padding-top: 4px;
            }
        }
    }
    .loginButton{
        background-color: #CCDAE2;
        color: #333333;
        width: 100%;
        font-size: 14px;
        text-transform: unset;
        margin-top: 30px;
    }

    input:-internal-autofill-selected {
        background-color: #fff!important;
    }
    .login-form-begetter {
        width: 445px;
        position: relative;
    }
    .login-form-abs-content {
        margin: 0 30px;
        background: #40B9C3;
        position: absolute;
    top: -50px;
        border-radius: 5px;
        padding: 20px 53px;
    }
    .login-form-abs-content .LoginHeading {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: #FFFFFF;
        margin: 20px auto 0px auto;
    }
 .login-form-abs-content {
        margin: 0 30px;
        background: #40B9C3;
        border-radius: 5px;
        padding: 20px 53px;
    }
    .login-form-abs-content-img {
        background: #FFFFFF;
        border-radius: 5px;
        padding: 14px 15px 15px 16px;
        width: auto;
        img {
            width: 100%;
        }
    }
    .login-form-begetter .loginFormStart {
        padding: 140px 0px 42px 0px;
        max-width: 100%;
        background: #FFFFFF;
        border-radius: 5px;
    }
    .login-form-begetter .loginFormStart form {
        padding: 0 35px;
    }
    .email-sec {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }
    .field-img-sec {
        width: 60px;
        text-align: left;
    }
    .login-pg.loginButton {
        background: transparent;
        box-shadow: unset;
        font-size: 18px;
        line-height: 21px;
        color: #3A6CB3;
        margin-top: 18px;
    }
}

@media ( max-width: 1170px ) {
    .login .loginForm .loginFormStart {
        width: 100%;
    }
}
@media ( max-width: 1024px ) {
    .login .loginForm .loginFormStart {
        width: 100%;
    }
}

@media ( max-width: 940px ) {
    .login { flex-direction: column;
            box-sizing: border-box;
        .logo { 
            display: block;
            border: none;
            width: 100%;
            height: auto;
            flex: 0;            
            padding: 0px 0 50px;
        }
        .loginForm { 
            display: block;
            width: 100%;
            height: auto;
            flex: 0;
        }
    }
}
@media ( max-width: 479px ) {
    .login { 
        .logo { 
            img {
                width: 55%;
            }
        }
        .loginForm{
            .loginFormStart {
                width: 100%;
            }
            .ForgotDiv {
                .MuiTypography-body1, p {
                    font-size: 13px;
                }
            }
        }
    }
}

@media ( max-width: 600px ) {
.login .login-form-begetter {
    width: 98% !important;
}
}


.forgot-password{
    .MuiFormControl-marginDense{
        width: 100%;
    }
}

.forgot-pass-form .MuiInputBase-input{
    border-bottom: 1px solid #B7B9BC;
}
