
.topNavigation {
    .outerSpan {
        display: flex;
        .projectName {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 500px;
            text-decoration: none;
            color: #014671;
            font-size: 16px;
            margin: 0px
        }
        .projectName:hover{
            cursor: pointer;
        }
    }
}