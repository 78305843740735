.categoryCard{
    padding: 8px 8px 0px 8px!important;
    border-radius: 5px;
    min-width: 180px;
    .MuiPaper-elevation8 {
        box-shadow: none;
    }
    .MuiPaper-rounded {
        background-color: #f6f6f6;
    }
    .totalCount {
        background-color: #fff!important;
    }
    .activeCount {
        .categoryCount {
            color: #2BAA0E;
        }
    }
    .inactiveCount {
        .categoryCount {
            color: #D0021B;
        }
    }
    .suppliersCount {
        .categoryCount {
            color: #2BAA0E;
        }
    }
    .MuiCardContent-root{
        width: auto;
        padding: 10px 21px 20px 21px!important;
    }
    .categoryName{
        text-align: left;
        color: #666666;
        font-size: 12px;
    }
    .categoryCount{
        font-weight: 600;
        text-align: left;
        font-size: 20px;
        color: inherit;
        margin: 0;
    }
}
// New SCSS

 .ProjectCard{
    min-width: 200px;
    min-height: 66px;
    width: 200px;
    border: 1px solid #80A3B8;
    border-radius: 5px;
    .card{
        .MuiGrid-root.MuiGrid-container{
            flex-wrap: nowrap;
        }
        svg{
            position: unset;
        }
        .task-details{
            margin-left: 7px;
            text-align: left;
            .task-name{
                font-weight: 500;
            }
        }
    }
} 