.inventoryStockContainer{
    .normalFilter{
        background: #F6F6F5 !important;
    }
    .selectedFilter{
        background: white !important;
    }
}

.createRfqContainer{
    .categoryRow {
        .MuiGrid-item {
            padding: 0 15px;
            display: flex;
            align-items: center;
        }
        .unitCol {
            .MuiInputBase-root {
                margin: 0px;
            }
        }
    }
    .vendorAutocomplete{
        margin-bottom: 15px;
    }
}

.rfqEditBtn {
    float: left;
    margin: 8px 0px!important;
}

.inventoryStockContainer .selectVehicle {
    text-align: left;
}

.inventoryTransaction {
    .paperContainer {
        background: #fff;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        table {
            .MuiPaper-elevation2 {
                box-shadow: none!important;
            }
        }
        .MuiPaper-elevation2 {
            box-shadow: none;
        }
    }
}
.add-material{
    .add-icon{
        cursor: pointer;
        background: #014671;
        border-radius: 50%;
        color: #fff;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        .MuiSvgIcon-root {
            width: 20px;
            height: 20px;
        }
    }
}
.inventoryStockContainer .MuiGrid-root.MuiGrid-container {
    background: #fff;
    margin-bottom: 10px;
    width: fit-content;
}
div.categoryCard{
 .selectedFilter {
    cursor: pointer;
    background: #6dc9d1 !important;
 }
    div.normalFilter {
        background: transparent !important;
    }
}
.addCustomer .paperContainer div.MuiFormControl-marginDense {
    width: 320px;
    max-width: 100%;
}
@media (max-width: 767px) {
.addCustomer .paperContainer .MuiGrid-item {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
}
}
