.content,
.title {
  padding: 0 20px;
  margin-top: 10px;
}

.MuiDialog-paperScrollPaper {
  max-height: auto;
}

.custom-modal {
  overflow-y: scroll;
  z-index: 1201;
  position: fixed !important;
  top: 6%;
  min-height: 450px;
  width: 85%;
  display: flex;
  box-shadow: inset 0 0 5px #ddd;
  background: #fff;
}

.MuiDialogTitle-root {
  background: white;
  color: black;
  padding: 16px 24px !important;
}

.primary {
  .MuiDialogTitle-root {
    background: white;
    color: black;
    padding: 24px;
  }
}

/*.titlebg{
  background-color: #EDEDED;
  width:100%;
  padding: 0px;
}*/

.MuiDialogActions-root {
  justify-content: flex-end !important;
}

.actionbtn {
  text-align: right;
  width: 100% !important;
}

.cancelBtn {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  background-color: #fff !important;
  text-transform: unset !important;
}


.MuiDialog-paperWidthMd {
  max-width: 1040px !important;
  padding: 0 0px 0px 0;
}

.cancel-btn,
.listbtn {
  border: 2px solid #014671 !important;
  font-size: 12px !important;
  box-shadow: none !important;
  background-color: #014671 !important;
  color: #fff !important;
  line-height: 16px !important;
  padding: 10px 20px !important;
  display: inline-block;
  margin: 0 0 0 10px !important;
  text-align: center;
  width: auto !important;
  border-radius: 4px !important;
  cursor: pointer;
  float: right;
  text-transform: unset !important;
  text-decoration: none !important;
}

.cancel-btn:hover,
.listbtn:hover {
  background-color: transparent !important;
  color: #014671 !important;
}

.createBtn.listbtn {
  margin: 0px !important;
}

.cancel-btn-white,
.filterBg {
  border: 2px solid #014671 !important;
  font-size: 12px !important;
  box-shadow: none !important;
  background-color: transparent !important;
  color: #014671 !important;
  line-height: 16px !important;
  padding: 10px 20px !important;
  display: inline-block !important;
  margin: 0 0 0 10px !important;
  text-align: center;
  width: auto !important;
  border-radius: 4px !important;
  text-transform: unset !important;
  text-decoration: none !important;
  cursor: pointer;
}

.cancel-btn-white:hover {
  background-color: #014671 !important;
  color: #fff !important;
}

.btnPrimary {
  border: 2px solid #014671 !important;
  font-size: 12px !important;
  box-shadow: none !important;
  background-color: #014671 !important;
  color: #fff !important;
  line-height: 16px !important;
  padding: 10px 20px !important;
  display: inline-block !important;
  margin: 0 0 0 10px !important;
  text-align: center;
  width: auto !important;
  border-radius: 4px !important;
  cursor: pointer;
  float: right;
  text-transform: unset !important;
  text-decoration: none !important;
}

.btnPrimary:hover,
.filterBg:hover {
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.4) !important;
}

.filterBg {
  padding: 10px 14px !important;
}



@media (max-width:479px) {

  #form-dialog-title .MuiTypography-h6 {
    .icon {
      margin: 4px -4px 0 0;
    }
  }
}

// New SCSS
.primary {
  .MuiDialog-paperFullWidth {
    min-width: 85% !important;
  }

  input {
    font-weight: 500 !important;
    color: #202122 !important;
  }

}
#form-dialog-title {
  padding: 20px 25px !important;
}
.addStageContainer {
  padding-top: 20px;
 table.MuiTable-root{
  thead{
    background-color: #F5F7F8 !important;
    th{
      font-weight: 400;
font-size: 16px !important;
line-height: 19px;
color: #555555 !important;
    }  
  }
  tbody{
    tr{
      td{
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #202122 !important;
        &.status{
          color: #E9A800 !important;
        }
      }
      img{
        margin-right: 10px;
      }
     
    }
  }
 }
  .list-title {
    margin: 20px 0;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 5px;
    h6 {
      padding: 20px;
    }
  }
  .assign {
    h6 {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;

    }

    .assign-user {
      color: #40B9C3;
      font-weight: 500;
      cursor: pointer;
      font-size: 16px !important;
    }
    .accordion-item{
      margin-top: 30px;
      border: 1px solid #E0E0E0;
      border-radius: 5px;
      padding: 0 !important;
      box-shadow: none !important;
      // .expanded{
      //   display: none;
      // }
      .css-15v22id-MuiAccordionDetails-root{
        padding: 20px 20px 20px 30px !important;
      }
     .accordion-name{
      margin: 0;
     }

    }
    .user-list-table {
      overflow: auto;
      width: 100%;
  
      thead {
        background: #F5F7F8;
  
        th {
          font-size: 16px;
          line-height: 19px;
          color: #555555;
          padding: 20px;
        }
      }
  
      tbody {
        tr {
          td {
            color: #202122 !important;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
  
            &.status {
              color: #E9A800 !important;
            }
          }
  
          &.subTotal {
            td {
              &:first-child {
                text-align: right;
              }
            }
          }
        }
      }
    }
    .total-task{
      tr{
        td{
          border: 0;
          &:first-child{
            min-width: 85%;
            width: 85%;
            font-size: 18px;
            line-height: 21px;
            color: #202122 !important;
          }
          &:last-child{
            font-weight: 600;
            font-size: 22px;
            line-height: 26px;
            text-align: right;
            color: #202122 !important;
            text-align: left;
          }
        }
      }
    }
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.accordion-item.css-1elwnq4-MuiPaper-root-MuiAccordion-root::before {
      background: none;
  }
  .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content{
    margin: 0 !important;
  }
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root{
    min-height: 0 !important;
    padding: 20px 20px 20px 30px !important;
  }
  }
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-multiline.css-8q2m5j-MuiInputBase-root-MuiInput-root{
    min-height: 90px;
  }
  .top-inputFields{
    align-items: flex-start;
  }
}
.actionbtn {
  display: flex;
  justify-content: center;

  .footer-btns {
    width: 100%;
    display: flex;
    justify-content: center;

  }
}
.accordion-wrapper {
  .dialog-body {
    .top-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
    }

    .MuiTextField-root {
      width: 100%;
      padding-right: 15px;
      margin-bottom: 15px;
    }

    .add {
      background: #FAFBFB;
      border: 1px solid #F0F0F0;
      border-radius: 5px;
      padding: 25px 25px 30px 25px;

      .add-btns-row {
        display: flex;
        justify-content: center;

        .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
          border: 1px solid #40B9C3;
          border-radius: 5px;
          width: 110px;
          height: 40px;
          display: flex;
          justify-content: center;
          color: #40B9C3 !important;
          background: transparent !important;
          margin: 0 20px;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;

        }

        button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-sghohy-MuiButtonBase-root-MuiButton-root {
          border: 1px solid #40B9C3;
          border-radius: 5px;
          width: 110px;
          height: 40px;
          display: flex;
          justify-content: center;
          color: #fff !important;
          background: #40B9C3 !important;
          margin: 0 20px;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }

  }
}
@media only screen and (max-width:1199px){
  .items-description{
margin-top: 20px;
  }
}
@media only screen and (max-width:767px){
  .addStageContainer .assign .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root,.addStageContainer .assign .accordion-item .css-15v22id-MuiAccordionDetails-root{
    padding: 10px !important;
  }
  .addStageContainer .list-title h6{
    padding: 10px !important;
  }
  .actionbtn .footer-btns{
    button{
      max-width: 200px;
      margin: 0 10px !important;
    }
  }
  .accordion-wrapper{
    .dialog-body{
      .add{
        padding: 10px !important;
      }
    }
  }
}
@media only screen and (max-width:576px){
  .actionbtn .footer-btns{
    flex-direction: column;
    align-items: center;
    button{
      margin: 3px !important;
    }
   
  }
}