@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100&display=swap');

body {
    font-family: 'Roboto', sans-serif !important;
}

.Homelayout.MuiPaper-elevation4 {
    box-shadow: none !important;
    width: calc(100% - 300px);
    margin-left: 300px;

    .mainTopbar {
        .MuiPaper-elevation4 {
            box-shadow: 10px 3px 16px #0000000A !important;
            border-bottom: 1px solid rgba(0, 0, 0, 0.10);
        }

        .avatarLogoContainer {
            margin-left: 20px;
            justify-content: space-between;
            cursor: pointer;
        }

        .personDetail {
            margin-left: 20px;
            color: orangered;
            display: flex;
            justify-content: center;
            text-align: left;
            flex-direction: column;
        }

        .personDetail p {
            margin: 0;
        }

        .personDetail {
            .name {
                font-size: 12px;
                color: #333333;
            }

            .designation {
                font-size: 10px;
                color: #121212;
                float: left;
            }
        }

        .MuiTypography-h6 {
            font-size: 20px;
            color: #121212;
        }

        .iconsContainer {
            .MuiSvgIcon-root {
                fill: #CCCCCC;
                width: 20px;
                height: 20px;
            }

        }

        .MuiAppBar-colorPrimary {
            color: black;
            background-color: white;
        }

        .avatarBorderLeft {
            height: 24px;
            border-right: 2px solid #cccccc;
            padding-left: 5px;
            color: #cccccc;
        }

        .avatarLogoContainer {
            .MuiIconButton-colorInherit {
                border: 0px solid #ef8168;
                padding: 1px;
            }

            .MuiAvatar-root {
                width: 40px;
                height: 40px;
            }

            .MuiAvatar-colorDefault {
                color: #fff;
                background-color: #014671 !important;
            }
        }

        .MuiToolbar-regular {
            min-height: 60px;
            padding-left: 25px;
            padding-right: 24px;
        }
    }
}

.MuiList-padding .MuiButtonBase-root,
.MuiList-padding .MuiPaper-root {
    margin-bottom: 3px !important;
}

.MuiList-padding .MuiPaper-root .MuiButtonBase-root {
    margin-bottom: 0px !important;
}

.MuiList-padding .MuiButtonBase-root:hover,
.MuiList-padding .MuiPaper-root:hover {
    background: #6dc9d1;
    border-radius: 5px;
    transition: .3s ease;
}


.mainDrawer {
    .makeStyles-toolbar-5 {
        display: none;
    }

    .MuiTypography-displayBlock {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: #fff;
    }

    .makeStyles-drawerPaper-4 {
        background: #FCFCFC !important;
    }

    .MuiListItemIcon-root {
        min-width: 32px;
        margin-left: 0px;
    }

    .sidebarLogo {
        text-align: left;
        padding: 0 42px;
        background: #fff;

        img {
            width: 100%;
            margin: 16px 0;
        }
    }

    .MuiSvgIcon-root {
        width: 22px;
        height: auto;
        fill: #fff;
    }

    #sidebar-menu-list {
        background: url(./../assets/sidebar-bg.png) no-repeat;
        padding: 12px 12px 16px 12px !important;
        background-position: 0 -280px;
        width: 100%;
        overflow-x: auto;
        scrollbar-color: #020202 #848484;
        scrollbar-width: thin;

        .MuiPaper-elevation.MuiPaper-rounded.menuList {
            background-color: unset !important;
        }

    }

    .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
        background-color: unset;
    }

    .MuiDrawer-paperAnchorDockedLeft {
        width: 300px;
        overflow: hidden;
    }

    .MuiAccordionDetails-root {
        padding-left: 0;
    }

    .css-41jtpm-MuiButtonBase-root-MuiAccordionSummary-root {
        width: 100%;
        padding: 0 16px;
    }

    .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
        background: #6dc9d1;
        border-radius: 5px;
    }

    .css-41jtpm-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
        min-height: 52px;
        background: #def2ff;
    }

    .MuiList-padding li.MuiListItem-root.MuiListItem-gutters {
        cursor: pointer;
    }

    .MuiListItem-gutters {
        padding-left: .5rem;
        padding-right: .5rem;
    }

    .MuiList-padding li.MuiListItem-root.MuiListItem-gutters:hover {
        background: #3c3c3c;
        border-radius: 5px;
        transition: .5s;
    }

    .MuiCollapse-vertical ul.MuiList-root .MuiListItem-root.activeList.MuiListItem-gutters {
        background: #3c3c3c !important;
    }

    .css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .MuiCollapse-vertical.MuiCollapse-entered {
        background: #262626;
    }

    .MuiDrawer-paperAnchorDockedLeft {
        background: #000;
        border-right: 0;
    }
}

.activeList {
    // border-left: 4px solid #EC684A!important;
    background-color: #6DC9D1 !important;
    border-radius: 5px !important;

    .MuiSvgIcon-root {
        fill: #FFF;
    }

    .MuiTypography-displayBlock {
        color: #FFF !important;
        font-weight: 700;
    }
}

.desktop-View {
    #primary-search-account-menu .MuiPaper-elevation8 {
        right: 8px;
        top: 60px !important;
        max-width: 170px !important;
        left: 89% !important;
    }
}

.MuiMenu-list .MuiMenuItem-root {
    font-size: 12px;
    color: #121212;
}

.menuList {
    box-shadow: none !important;
    margin: 0px !important;

    .MuiTypography-body1 {
        font-size: 15px;
        color: #fff;
    }
}

.addItems {
    .paperContainer {
        padding: 20px !important;
    }
}


.Mui-error {
    color: #ff0000 !important;
    margin: 0px 0 -2px !important;
}

.mobileHeader,
.sideBarCloseBtn,
.mobileUser {
    display: none;
}

@media (max-width: 1025px) {
    .mobileHeader {
        display: flex;
        justify-content: flex-start;

        .MuiSvgIcon-root {
            width: 30px;
            height: 28px;
        }

        .sidebarLogo {
            text-align: left;

            img {
                width: 65%;
                margin-top: 13px
            }
        }
    }

    .mainDrawer {
        .sidebarLogo img {
            width: 100%;
            margin: 10px 0 10px;
        }

        //     .MuiPaper-root { 
        //     background-color: #FCFCFC!important;

        //  }
    }

    .sideBarCloseBtn {
        display: block;
        text-align: right;

        .MuiSvgIcon-root {
            width: 20px;
            height: 23px;
        }

    }

    .moduleName {
        display: none !important;
    }

    .Homelayout.MuiPaper-elevation4 .mainTopbar .MuiToolbar-regular {
        padding-left: 10px;
    }

    .makeStyles-drawerPaper-23 {
        width: 260px !important;
    }

}

@media (max-width: 350px) {
    .mobileHeader {
        .sidebarLogo {
            text-align: left;

            img {
                width: 76%;
                margin-top: 14px;
            }
        }
    }

    .Homelayout.MuiPaper-elevation4 .mainTopbar .MuiToolbar-regular {
        padding-right: 5px;
    }
}

.link {
    cursor: pointer;
    color: gray;
}

.MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0 !important;
}

.header-caption-section h2 {
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    text-transform: capitalize;
    color: #555555;
}

.makeStyles-root-2 .makeStyles-content-7 {
    // background-color: #eee;
    background-color: #fff;
}

div.MuiPopover-paper {
    min-width: 100px;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded .personDetail {
    padding-left: 16px;
    display: flex;
    padding-right: 15px;
}

.user-profile-list {
    margin-left: 10px;

    p {
        font-size: 12px;
        margin-bottom: 5px;
        margin-top: 0;
    }
}

.tableContainer {
    position: relative;
}

div.MuiListItem-root {
    padding-top: 5px;
    padding-bottom: 5px;
}

div.css-o4b71y-MuiAccordionSummary-content {
    margin: 7px 0;
}

ul.MuiList-root.MuiList-padding {
    width: 100%;
}

@media (min-width: 1025px) {
    .MuiDrawer-docked {
        width: 300px !important;
    }
}

@media (max-width: 1024px) {
    .Homelayout.MuiPaper-elevation4 {
        width: 100%;
        margin-left: 0;
    }

    .sideBarCloseBtn {
        position: absolute;
        right: 4px;
        top: 4px;

        .MuiIconButton-colorInherit {
            background: #000 !important;
            padding: 6px 7px;
        }
    }
}

#layoutAlign {
    width: calc(100% - 300px);

    @media (max-width: 1028px) {
        width: 100%;
    }
}

.sidebar-light {
    .MuiDrawer-paperAnchorDockedLeft {
        background: #ffffff;
        border-right: 1px solid rgba(0, 0, 0, 0.07);
        box-shadow: 10px 3px 16px #0000000A !important;
    }

    #sidebar-menu-list {
        background: #ffffff;

        // border-top: 1px solid rgba(0, 0, 0, 0.12);
        .MuiTypography-displayBlock,
        .MuiTypography-body1 {
            color: #000000;
        }

        .MuiSvgIcon-root {
            fill: #014671;
        }

        .MuiAccordionSummary-expandIconWrapper {
            .MuiSvgIcon-root {
                fill: #000000;
            }
        }

        .css-1elwnq4-MuiPaper-root-MuiAccordion-root::before {
            display: none;
        }

        .MuiCollapse-vertical.MuiCollapse-entered {
            background: #def2ff;
        }


        .MuiAvatar-colorDefault {
            background-color: #014671;

            .MuiSvgIcon-root {
                fill: #ffffff;
            }
        }

        .activeList {
            background-color: #014671 !important;
            border-radius: 5px !important;

            .MuiSvgIcon-root {
                fill: #ffffff;
            }
        }


    }

    .MuiList-padding .MuiButtonBase-root:hover,
    .MuiList-padding .MuiPaper-root:hover {
        background: #014671;
    }

    .MuiList-padding .MuiButtonBase-root:hover {
        .MuiTypography-root {
            color: #ffffff !important;
        }

        .MuiSvgIcon-root {
            fill: #ffffff !important;
        }
    }

    .MuiList-padding li.MuiListItem-root.MuiListItem-gutters:hover {
        background: #014671;

        .MuiTypography-root {
            color: #ffffff !important;
        }

        .MuiSvgIcon-root {
            fill: #ffffff !important;
        }
    }
}

.header-caption-section {
    h2 {
        font-size: 16px;
        text-transform: uppercase;
        color: #1976d2;
        font-weight: 500;
        margin: 0;
    }
}