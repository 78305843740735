.attendanceContainer {
	padding: 20px;
	box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
	background-color: #fff;
}
.attendanceSelectFilter {
	display: flex;
	justify-content: flex-end;
	margin-top: -15px;
    margin-bottom: 10px;
    .MuiOutlinedInput-marginDense {
    	background-color: #fff;
    	text-align: left;
    }
}
.rbc-day-bg, .rbc-month-row{
	cursor: crosshair !important;
}
.rbc-event-content{
	white-space: normal !important;
}

@media (max-width: 1170px) {
	.attendanceContainer {
		overflow-x: auto;
		.attendanceContainerMobile {
			width: 200px;
			.rbc-calendar {
				width: 1024px!important;
				padding-right: 20px;
			}
		}
	}
}
@media (max-width: 768px) {
	.timesheetScrollMobile {
		overflow-x: scroll;
		.timesheetScroll {
			width: 200px;
			.mobileTimesheet {
				width: 900px;
			}
		}
	}
}
@media (max-width: 650px) {
	.timesheetFilters {
		span {
			.btnPrimary {
				margin: 20px 15px 20px 0px!important;
			}
		}
		span:last-child {
			.btnPrimary {
				margin: 20px 0px 20px 0px!important;
			}
		}
	}
}
@media (max-width: 350px) {
	.timesheetFilters {
		flex-direction: column;
		span {
			.btnPrimary {
				margin: 20px 0px 0px 0px!important;
				width: 100%!important;
			}
		}
	}
	.actionbtn {
		.actionbtn {
			button {
				width: 100%!important;
				margin-bottom: 10px!important;
			}
		}
	}
}
button.css-22ujne-MuiButtonBase-root-MuiButton-root {
    border: 1px solid #6dc9d1;
    color: #6dc9d1;
}
@media (max-width: 767px) {
.timesheetFilters {
    flex-direction: column;
}
div.timesheetFilters span .btnPrimary {
    margin: 0px 15px 10px 0px !important;
}
}