.createInvoiceContainer{
    .tableHeader{
        padding: 0 20px;
        background-color: #F4F4F4;
    }
    .evenContent{
        padding: 0 20px;
        background-color: #FCFCFC;
    }
    .oddContent{
        padding: 0 20px;
        background-color: #F8F8F8;
    }
    .subTotalContainer{
        text-align: center;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 10px;
        background: #E5ECF0;
        border-radius: 4px;
        margin: 0 auto;
        p {
            margin: 5px 0;
        }
    }
}

.invoiceFilters {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 16px;
    .MuiInputBase-inputMarginDense {
        background-color: #fff;
        text-align: left;
        font-size: 14px;
    }
}
.userCategoryContainer {
    margin-bottom: 10px;
}

.createInvoiceContainer .subTotalContainer {
    margin-left: 10px;
}


.categoryCard .selectedFilter {
    cursor: pointer;
    background: #6dc9d1 !important;
}

.categoryCard {
    background: #FFF !important;   
}

.userCategoryContainer {
    margin-bottom: 20px;
}

.MuiGrid-spacing-xs-2 {
    margin: 0px !important;
}

.MuiGrid-root.buttonContainer.MuiGrid-container .MuiButton-containedPrimary {
    min-width: 150px !important;
    margin-bottom: 10px;
}

.add-material-po .MuiTableContainer-root.table-container table.MuiTable-root tbody.MuiTableBody-root tr.MuiTableRow-root td.MuiTableCell-root.MuiTableCell-body:first-child {
    width: 15%;
}