.dashboard-container {
    background-color: white;
    border-radius: 3px;
    padding: 20px;

    .filter-button {
        float: right;
    }

    .divider {
        margin-top: 41px;
    }
}

text {
    fill: black !important;
    cursor: pointer !important;
    font-size: 10px;
    font-weight: 600 !important;

}

a {
    cursor: pointer;
}

.graph-container {
    background: white;
    // padding: 10px 25px;
    margin-top: 20px;
    padding: 10px 45px;

    .graph-text-container {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    .revenue-text {
        font-size: 21px;
        margin: 0;
    }

    .revenue-amount {
        font-size: 31px;
        margin: 0;
    }

    svg {
        height: fit-content !important;
        overflow: visible;
    }
}

@media (max-width: 767px) {
    .graph-container .revenue-amount {
        font-size: 14px;
    }

    .graph-container .revenue-text {
        font-size: 16px;
        margin-right: 4px;
    }

    div.css-1osj8n2-MuiGrid-root {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

}

.projectCategoryWrapper {
    margin-top: 30px;

    .projectCategory {
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        padding: 20px;
        margin: 20px 10px 20px 0;
        background: #fff;
        border-radius: 5px;
        min-height: 293px;

        &.proj-cate-last {
            margin-right: 0;
        }

        &.pro-mate {
            min-height: 212px;
        }

        .projectCategoryInnerSection {

            //display:flex;
            h3 {
                font-size: 16px;
                padding-right: 10px;
                margin: 0;
                text-align: left;
                margin-top: 3px;
            }

            .cate-active-project {
                .table {
                    .MuiPaper-elevation2 {
                        // width:55%
                    }

                    .MuiTable-root tbody tr td {
                        border-bottom: 1px solid #7A7979 !important;
                    }

                    .MuiTable-root tbody tr th {
                        fontWeight: 600 !important;
                        backgroundColor: #F4F4F4 !important;

                    }

                }

            }
        }


        .CategoryMaterial {
            margin: 0;
            font-size: 15px;
        }

        .CategoryMaterialCost {
            display: flex;
            justify-content: space-between;
            padding-top: 20px;

            div {
                border: 1px solid #e3e2e2;
                padding: 10px 20px;
                margin-right: 10px;
                border-radius: 5px;
                background: #f7f7f7;

                p {
                    margin: 0;
                    font-size: 14px;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .CategoryPurchaseOrder {
            font-size: 15px;
            text-align: left;
            color: #6DC9D1 !important;
            cursor: pointer;
        }





    }
}

.dashboard-datepicker {
    .datepicker-block {
        .MuiInputBase-input {
            padding: 10px 15px;
            border: 1px solid #e2e2e2;
            font-size: 12px;
            border-radius: 4px;
        }

        .MuiFormControl-marginDense {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}