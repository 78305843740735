@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif !important;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.tableContainer {
  margin-top: 0px !important;
}

.tableContainer .MuiPaper-elevation2 {
  box-shadow: none;
  margin-top: 0px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

html {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    background-color: gray;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: black;
    opacity: 0.5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
    background-color: black;
    opacity: 0.5;
  }
}

.tableContainer .MuiTable-root {
  width: 97%;
  margin: 0 auto;
}

.tableContainer .MuiTableCell-root {
  border-bottom: 1px solid #E7E7E7;
}

.tableContainer .MuiTableCell-paddingCheckbox:last-child {
  padding-left: 0;
  padding-right: 15px;

}

.headingContainer {
  padding: 0px 0px 0px 20px;
  margin-bottom: 0px !important;
  height: 40px
}

.userOverviewText {
  color: #121212;
  font-weight: 400 !important;
  font-size: 16px;
}

.topNavigation {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  padding: 0px 0px 0px 20px;
  min-height: 40px;
  margin: 0px !important;

  a {
    text-decoration: none;
    color: #014671;
    font-size: 16px;
  }

  span.arrow {
    display: inline-block;
    padding: 0 8px;
    font-size: 16px;
  }

  a:hover {
    cursor: pointer;
  }
}

.display-none {
  display: none !important;
}

.table-head {
  background-color: #f4f4f4;
  padding: 0 15px;

  tr th {
    text-align: center !important;
    font-weight: bold
  }

  .MuiTableCell-root {}
}

.table-body {
  tr td {
    text-align: center !important;
  }
}

.MuiTableContainer-root {
  overflow: unset !important;
}

.notistack-SnackbarContainer {
  z-index: 100100 !important;
}

input[type="number"] {
  &.form-control {

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: unset;
    }

    &:hover {

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        appearance: textfield;
        opacity: 1;
        height: 1.2rem;
        padding-inline-end: 5px;
      }
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}