.modal {
  padding: 0 !important;

  .modal-dialog.modal-90w {
    max-width: none !important;
    margin: auto;
    width: 97%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .modal-dialog.modal-70w {
    max-width: none !important;
    margin: auto;
    width: 70%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .create-modal {
    .modal-content {
      padding: 15px;
    }

    .modal-header {
      padding: 10px 0;
      padding-top: 0;
    }

    .modal-body {
      padding: 0;
    }

    .modal-title {
      font-size: 18px;
      text-transform: uppercase;
      color: #1976d2;
    }
  }
}

.modal-backdrop.show,
.modal {
  z-index: 9999 !important;
}

.input-block {
  position: relative;
  margin-top: 10px;

  .input-label {
    position: absolute;
    top: -10px;
    left: 15px;
    font-size: 12px;
    color: #8a8ea5;
    display: inline-block;
    background: #fff;
    padding: 1px 8px;
    z-index: 5;
  }

  .form-control {
    padding: 10px 15px;
    border-color: #e2e2e2;
    font-size: 12px;
    padding-top: 16px;

  }

  textarea {
    resize: none;
    padding: 10px 15px !important;
    padding-top: 16px !important;
  }

  .lock-icon {
    width: 32px;
    height: 32px;
    display: inline-block;
    position: absolute;
    right: 12px;
    background: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 100%;
    text-align: center;
    line-height: 26px;
    top: -15px;

    svg {
      font-size: 20px;
      fill: #f66;
    }
  }

  .input-icon {
    position: absolute;
    right: 10px;
    top: 12px;

    svg {
      font-size: 18px;
      fill: #8a8ea5;
    }
  }

  p.doc-info {
    padding: 10px 15px;
    padding-top: 10px;
    padding-top: 10px;
    border: 1px solid #a7cbee;
    font-size: 12px;
    padding-top: 16px;
    border-radius: 5px;
    color: #2f83d4;

    span {
      svg {
        font-size: 18px;
        margin-left: 5px;
        fill: #f66;
      }
    }
  }

  &.input-block-sm {
    margin: 0;
    // width: 130px;
    position: relative;

    .form-control {
      padding: 5px 10px;
      height: 35px;
      font-size: 11px;
      color: #000;

      @media (min-width: 1600px) and (max-width: 5000px) {
        font-size: 12px;
      }
    }

    .lock-icon {
      width: 22px;
      height: 22px;
      right: 8px;
      line-height: 18px;
      top: -6px;

      svg {
        font-size: 15px;
      }
    }
  }
}

.datepicker-block {
  .MuiInputBase-input {
    padding: 10px 15px;
    border: 1px solid #e2e2e2;
    font-size: 12px;
    padding-top: 19px;
    border-radius: 4px;
  }

  .MuiFormControl-marginDense {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.nav-tabs {
  border-color: #e2e2e2;

  li {
    .nav-link {
      border: none;
      padding: 0;
      border-bottom: 2px solid transparent;
      padding-bottom: 10px;
      color: #b0b0b0;
      text-transform: uppercase;
      font-weight: 500;
      margin-right: 30px;
      margin-top: 10px;

      &.active {
        color: #40b9c3;
        border-bottom: 2px solid #40b9c3;
      }
    }
  }
}

.table-row-color {
  thead tr:nth-child(odd) {
    th {
      background-color: #f5f5f5;
    }
  }

  tbody tr:nth-child(even) {
    td {
      background-color: #f5f5f5;
    }
  }
}

.table.table-create {

  th,
  td {
    border: none;
    text-align: right;
  }

  thead {
    tr {
      border: none;

      th {
        color: #565656;
        font-size: 13px;
        font-weight: 500;

        &:first-child {
          width: 40%;
        }

        &:last-child {
          padding-left: 50px;
        }
      }
    }
  }

  tbody {
    tr {
      th {
        color: #565656;
        font-size: 13px;
        font-weight: 500;

        &:first-child {
          width: 40%;
        }

        &:last-child {
          padding-left: 50px;
        }
      }
    }
  }

  tr {
    border-color: #e5e5e5;
  }

  tbody {
    tr {
      td {
        color: #7a7e94;
        font-size: 12px;
        vertical-align: bottom;

        @media (min-width: 1600px) and (max-width: 5000px) {
          font-size: 14px;
        }

        span {
          display: block;
        }

        .tableinfo {
          font-size: 18px;
          text-align: right;
        }

        &:last-child {
          padding-left: 50px;
          vertical-align: middle;

          svg {
            fill: #40b9c3;
            font-size: 30px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.table.table-material {
  margin: 0;

  th,
  td {
    text-align: left;
  }

  thead {
    tr {
      border: none;

      th {
        color: #565656;
        font-size: 12px;
        font-weight: 500;

        @media (min-width: 1600px) and (max-width: 5000px) {
          font-size: 13px;
        }

        &:first-child {
          width: auto;
        }

        &:last-child {
          padding-left: .5rem;

        }

      }
    }
  }

  tbody {
    tr {
      td {
        vertical-align: middle;

        &:last-child {
          padding-left: .5rem;

        }

        .deleteicon {
          fill: #f66 !important;
          font-size: 20px !important;
          cursor: pointer;
        }

        .table-text-sm {
          font-size: 11px;
          font-weight: 500;
        }

        .add-material {
          font-size: 11px;
          cursor: pointer;
          font-weight: 500;
          color: #40b9c3;

          // width: 100px;
          svg {
            font-size: 17px;
            position: relative;
            top: -2px;
            color: #40b9c3;
          }
        }

        &.text-right {
          text-align: right !important;
        }
      }
    }
  }

  @media (max-width: 600px) {
    width: 1200px;
  }
}

.table.table-material-ch {
  @media (max-width: 600px) {
    width: 900px;
  }
}

.total-table {
  background: #f3f9ff;
  border: 1px solid #e5e5e5;
  margin-top: 20px;
  border-radius: 5px;

  .table.table-create {

    tr,
    td,
    th {
      background: transparent;
    }

    tr {
      border-color: #C9D9E6;
    }

    tbody {
      tr {
        border-bottom: 0px solid;

        td {
          color: #014671;

          span {
            text-align: right;
          }
        }
      }
    }

    thead {
      th {
        color: #8a8ea5;
      }
    }
  }

}

.primarybtn,
.secondarybtn {
  font-size: 12px !important;
  padding: 8px 15px !important;
  background: #40b9c3 !important;
  border: none !important;
  border-radius: 4px !important;
  text-transform: uppercase;

  @media (min-width: 1600px) and (max-width: 5000px) {
    font-size: 14px !important;
  }
}

.secondarybtn {
  background: #014671 !important;
}

.secondarybtn-outline {
  background: #fff !important;
  border: 1px solid #014671 !important;
  color: #014671 !important;
}

.cancelbtn {
  background: #eb684a !important;
}

.btn-sm {
  font-size: 11px !important;
  padding: 5px 15px !important;
}

.material-list-block {
  border: 1px solid #e5e5e5;
  border-radius: 5px;

  // overflow: hidden;
  h2 {
    font-size: 13px;
    text-transform: uppercase;
    padding: 8px;
    border-bottom: 1px solid #e5e5e5;
    text-align: left;
  }
}

.empty-icon {
  width: 30px;
  height: 30px;
}

.create-table-block {
  border: 1px solid #e5e5e5;
  padding: 15px;
  border-radius: 5px;
}

.table-responsive {
  overflow: auto;
}

.btn-res {
  @media (max-width: 600px) {
    margin-top: 20px !important;
  }
}

.autocomplete-block {
  position: initial;

  .MuiAutocomplete-root {
    border: 1px solid #e2e2e2;
    font-size: 12px;
    border-radius: 5px;

    label,
    .MuiInputBase-root-MuiInput-root::before,
    .MuiInputBase-root-MuiInput-root::after {
      display: none !important;
    }

    .MuiAutocomplete-inputRoot {
      margin-top: 0 !important;
    }

    .MuiInput-input {
      padding: 12px 15px !important;
      font-size: 13px !important;
    }

  }

  &.autocomplete-block-ch {
    .MuiAutocomplete-root {
      .MuiInput-input {
        padding: 4px 15px !important;
        padding-top: 4px;
        font-size: 11px !important;
        padding-top: 12px !important;
      }
    }
  }
}

.fileupload-section {
  .file-section-part {
    .file-part {
      height: initial;
      border: 1px dashed #8bbae8;
      border-radius: 5px;
    }
  }

  .dropzone {
    padding: 12px;
    border: none;
    background: none;
  }

  .attachmentText {
    font-size: 12px;
    color: #2680eb;
    margin: 0;
    font-weight: 500;

    .MuiSvgIcon-root {
      fill: #2680eb;
      transform: rotate(270deg);
      -webkit-transform: rotate(270deg);
      -moz-transform: rotate(270deg);
      -o-transform: rotate(270deg);
      font-size: 20px;
    }
  }

  .file-part label {
    color: #2680eb;
    font-size: 14px;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    bottom: 0;
    display: none;
  }
}

.error-text {
  font-size: 11px;
}


.content-heading {
  font-size: 18px;
  text-transform: uppercase;
  color: #1976d2;
  text-align: left;
}

.breadcrumb-block {
  li {
    text-transform: uppercase;
    font-size: 11px;
  }

  a {
    color: #535353;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      color: #1976d2;
    }
  }

  .active {
    font-weight: 600;
  }
}

.purchase-id-block {
  label {
    font-size: 14px;
    color: #8a8ea5;
  }

  p {
    font-size: 14px;
    color: #202122;
    font-weight: 500;
  }
}

.input-block {
  .textarea-ht {
    height: 128px;
  }
}

// .makeStyles-root-2 .makeStyles-content-7,.MuiBackdrop-root{
//   background-color: #fff!important;
// }
.MuiDialog-root {
  z-index: 99999 !important;
}

// .MuiDialog-root .MuiBackdrop-root{
//   background-color: rgba(0, 0, 0, 0.5)!important;
// }
.text-left {
  text-align: left !important;
}

.makeStyles-drawer-7 {
  @media (max-width: 1028px) {
    width: auto !important;
  }
}

.makeStyles-content-10 {
  @media (max-width: 1028px) {
    width: 100% !important;
    margin-left: 0 !important;
    background: #fff;
  }
}

.purchase-order-block {
  @media (max-width: 1028px) {
    padding: 10px;
  }
}

hr {
  border-color: #858585 !important;
}

.add-item-text {
  margin: 0;
  text-align: left;
  font-size: 12px;
  padding-top: 25px;
  cursor: pointer;

  @media (max-width: 767px) {
    text-align: right;
    padding-top: 0;
  }
}

.input-block.total-text-block {
  .input-label {
    left: 0;
    padding: 1px 0;
  }

  p {
    margin: 0;
    text-align: left;
    font-size: 14px;
    padding-top: 16px;
  }
}

.autocomplete-width {
  width: 50% !important;
}

.text-right {
  text-align: right !important;
}

.lock-input {
  .lock-icon {
    right: -8px !important;
  }
}

.item-width {
  width: 30% !important;
}

.contractor-width {
  width: 35% !important;
}

.desc-width {
  width: 38% !important;
}

.total-width {
  width: 9% !important;
}

.qty-width {
  width: 7% !important;
}

.cost-width {
  width: 10% !important;
}

.action-dropdown {
  .btn {
    padding: 0;
  }

  svg {
    fill: #7A7E94 !important;
  }
}

.action-block-list {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    font-size: 13px;
    margin-bottom: 5px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;

    &:last-child {
      margin: 0;
      padding: 0;
      border: 0px solid #ddd;
    }

    &:hover {
      color: #40b9c3;
    }
  }
}

.popover-body {
  padding: 10px !important;
}

.input-block.search-block .form-control {
  padding-top: 10px;
  padding-left: 35px;
}

.search-block .search-icon {
  position: absolute;
  left: 10px;
  top: 8px;
}

.search-block .search-icon svg {
  fill: #7A7E94;
  font-size: 17px;
}

.download-icon,
.filter-icon {
  width: 40px !important;
  height: 40px;
  display: inline-block;
  line-height: 38px;
  border: 1px solid #ddd;
  text-align: center;
  position: 0;
  border-radius: 5px;
  cursor: pointer;
}

.download-icon svg,
.filter-icon svg {
  font-size: 22px;
  position: relative;
  right: 3px;
  fill: #7A7E94;
}

.filter-icon svg {
  right: 0;
  font-size: 20px;
}

.view-block {
  label {
    font-size: 13px;
    margin-bottom: 5px;
    color: #8a8ea5;
    text-align: left;
    display: block;
  }

  p {
    margin: 0;
    color: #565656;
    font-size: 14px;
    text-align: left;
  }
}

.table-checkbox {
  text-align: center;
  .form-check-input {
    width: 20px;
    height: 20px;
    border-color: #dcdcdc !important;
  }
}

.btn-ch {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.MuiDialog-paper {

  h2 {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;

    .MuiGrid-item {
      font-size: 18px;
      text-transform: uppercase;
      color: #1976d2;
    }

    svg {
      fill: #f66;
    }
  }
}

body {
  background: #fff !important;
}

.table.table-variation {
  thead {
    tr {
      th {
        &:first-child {
          width: 20%;

          @media (max-width: 767px) {
            width: auto;
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        .tableinfo {
          @media (max-width: 767px) {
            width: 140px;
          }
        }
      }
    }
  }
}

.variation-select {
  @media (max-width: 767px) {
    width: 200px;
  }
}

.history-table {

  th,
  td {
    background: #f7f7f7 !important;
  }

  th:first-child,
  td:first-child {
    padding-left: 55px !important;
  }

  h3 {
    font-size: 14px;
    text-transform: capitalize;
    color: #000;
    text-align: left;
    margin: 0;
    padding: 10px;
    padding-left: 55px;
    background: #f7f7f7;
    border-bottom: 1px solid #e2e1e1;
  }
}

.expand-icon {
  width: 45px;

  svg[aria-expanded="true"] {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }
}

.amount-block {
  .input-icon {
    right: auto;
    left: 10px;
  }

  .form-control {
    padding-left: 30px;
  }

  svg {
    fill: #8a8ea5 !important;
    font-size: 18px !important;
  }

  &.input-block-sm {
    margin: 0;
    // width: 130px;
    position: relative;

    .input-icon {
      top: 10px;
    }

    .form-control {
      padding: 5px 10px;
      padding-left: 30px;
      height: 35px;
      font-size: 11px;
      color: #000;

      @media (min-width: 1600px) and (max-width: 5000px) {
        font-size: 12px;
      }
    }
  }

}

.tbl-fixed {
  table-layout: fixed;

  @media (max-width: 1028px) {
    width: 1500px !important;
  }
}

.table.tbl-fixed-width {
  tbody {
    tr {
      td {
        width: 25%;
      }
    }
  }

  thead {
    tr {
      th {
        width: 25%;
      }
    }
  }
}

.table.budget-table {
  thead {
    tr {
      th {
        width: 8%;

        &:first-child {
          width: 15%;
        }
      }
    }
  }

  .collapse {
    thead {
      tr {
        th {
          padding: 0;
        }
      }
    }

    tbody {
      tr {
        td {
          background: #f7f7f7;
        }

        .collapse-title {
          color: #000;
        }
      }
    }
  }
}

.budget-task {
  position: relative;
  padding-right: 40px;

  .expand-icon {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 30px;
    text-align: center;
    cursor: pointer;
  }
}

.task-edit-icon {
  display: inline-block !important;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
    margin-left: 4px;
  }
}

.customer-form-block {
  border: 1px solid #e5e5e5;
  border-radius: 5px;

  h2 {
    font-size: 13px;
    color: #000;
  }

  .customer-form-heading {
    padding: 10px 15px;
    border-bottom: 1px solid #e5e5e5;
  }
}

.table-checkbox-ch {
  label {
    margin-left: 8px;
    margin-top: 2px;
  }
}

.status-badge {
  display: inline-block !important;
  padding: 3px 10px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;

  &.active {
    background: #cff8e0;
    color: #179b4f;
  }

  &.inactive {
    background: #ffcac5;
    color: #e74c3c;
  }
}

.form-switch {
  .switch-checkbox {
    width: 38px !important;
    height: 18px;
    border-color: #bfbfbf;
    box-shadow: none !important;

    &:checked {
      background-color: #3cae6c;
      border-color: #3cae6c;
    }
  }

  .switch-checkbox.inactive {
    &:checked {
      background-color: #e74c3c;
      border-color: #e74c3c;
    }
  }
}

.action-edit {
  svg {
    fill: #888b9f !important;
    font-size: 22px !important;
  }
}

.chk-width {
  width: 50px !important;
}

.usercheckbox {
  width: auto;
  display: inline-block;

  .form-check-label {
    margin-top: 5px;
    margin-left: 8px;
  }
}

.content-heading-lg {
  color: #000;
  font-size: 13px;
}

.content-heading-sm {
  font-size: 10px;
  color: #000;
}

.tblfooter {
  td {
    background: #f3f9ff !important;
    color: #014671 !important;
  }
}

.label-default {
  font-size: 12px;
  color: #8a8ea5;
  margin-bottom: 8px;
}

.table.table-material.w-100-lg {
  @media (max-width: 767px) {
    width: 600px;
  }

}

.addicon {
  height: 45px !important;
  width: 45px !important;
  line-height: 45px !important;
  border-color: #1976d2;
  color: #1976d2;

  svg {
    right: 0;
    fill: #1976d2;
  }
}

.help-text {
  font-size: 9px;
}

.catalog-select {

  .css-1orw215-MuiFormControl-root {
    @media (max-width: 767px) {
      width: 100% !important;
      margin-bottom: 10px;
    }
  }

  .w-res-100 {
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }
}

.save-icon {
  svg {
    cursor: pointer;
  }

  .MuiSvgIcon-root {
    fill: #eb684a !important;
  }
}

.project-delete-icon {
  margin-left: 5px;
  font-size: 24px !important;
}

.tbl-fixed-ch {
  table-layout: fixed;

  @media (max-width: 1028px) {
    width: 800px;
  }
}

.border-primary-app {
  border-color: #014671 !important;
}

.fill-green {
  fill: #2e7d32 !important;
}

.fill-red {
  fill: #ef5350 !important;
}

.tbl-action {
  width: 9% !important;
}

.tbl-heading {
  font-size: 13px;
  text-transform: uppercase;
  margin: 0px;
}

.tbl-header-link {
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  color: #40b9c3;
}

.tbl-header-link-btn {
  width: 132px;
}

.tbl-action-btn {
  align-content: center;
  color: #40b9c3;
  cursor: pointer;
}

.chart-heading {
  h2 {
    font-size: 15px;
    text-transform: uppercase;
    color: #000;
    text-align: left;
  }
}

.chart-block {
  height: 100% !important;

  h3 {
    margin: 0;
    font-size: 13px;
    text-transform: uppercase;
    color: #1976d2;
    text-align: left;
    padding: 10px 15px;
    border-bottom: 1px solid #e2e2e2;
  }

  .table {
    table-layout: fixed;
  }

  .project-name-width {
    width: 40% !important;
  }

  .project-name-width-ch {
    width: 30% !important;
  }

  .table-text-hidden {
    display: block;
    // width: 138px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .proj-list-tbl {
    .project-name-width {
      width: 55% !important;
    }

    @media (max-width: 767px) {
      width: auto !important;
    }
  }
}

.donut-chart {
  padding: 15px;
  height: 245px;
}

.donut-chart-ch {
  padding: 10px;
  height: 200px;
}

.bar-chart {
  padding-top: 0;
  padding: 15px;
  height: 245px;
}

.bar-chart-ch {
  padding-top: 0;
  padding: 10px;
  height: 200px;
}

.tbl-expand-icon {
  position: absolute;
  right: 12px;
  top: 2px;
  bottom: 0;
  margin: auto;
  display: block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #8c8fa2;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
}

.tbl-success-badge {
  display: inline-block !important;
  background: #AFF1C3;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 10px;
  color: #316D48;
}

.tbl-danger-badge {
  color: #9C3D44;
  background: #FFD9D9;
}

.action-edit.text-success svg {
  fill: #316d48 !important;
}

.modal-dashboard {
  .modal-title {
    font-size: 18px !important;
    text-transform: uppercase;
    color: #1976d2;
  }
}

.row.row-padding>* {
  padding-right: calc(var(--bs-gutter-x) * .2);
  padding-left: calc(var(--bs-gutter-x) * .2);
}

.dashboard-datepicker {
  position: absolute;
  right: 0;
  top: -15px;

  .input-block {
    .form-control {
      padding-top: 10px !important;
    }

    .input-icon {
      top: 8px !important;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    top: 50px;
  }

  @media (max-width: 767px) {
    position: relative;
    top: 0;
    margin-bottom: 15px;
  }
}